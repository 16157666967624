import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProductAss'
})
export class FilterProductAssPipe implements PipeTransform {

  transform(values: any[]): any[] {
    const result = values.filter((v) => !v.trasporto).filter((v) => !v.assflag);

    if ( result && result.length > 0 ){
      return result;
    } else {
        return [ -1 ];
    }
  }
}
