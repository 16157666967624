import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { ServiceProductModalService } from '../../service/serviceProductModal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-smaltimento',
  templateUrl: './smaltimento.component.html'
})

export class SmaltimentoComponent implements OnInit {
  @Input() smaltim: boolean = false;

  url: string;

  constructor(
    private _translate: TranslateService
  ) {
    this.url = this._translate.instant('services.urlSmaltimento');
  }

  ngOnInit(): void {}
}
