<div class="Modal" (click)="closeModal.emit(false)">
    <div class="Modal-inner" (click)="$event.stopPropagation()" [ngClass]="modalClass">
        <div class="Modal-content">
            <div *ngIf="modalClass=='assistance'">
              <i *ngIf="!disabledFirstStep" class="bi bi-arrow-left" (click)="previousStepModal.emit(1)"></i>
            </div>
            <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
            <ng-content></ng-content>
            <div *ngIf="modalClass=='assistance' && isMobile" class="text-center container-btn">
              <a class="annulla"
                (click)="closeModal.emit(false)">
                {{ "common.annulla" | translate | uppercase}}
              </a>
            </div>
        </div>
    </div>
</div>
