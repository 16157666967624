import { ApiService } from "../service/api.service";
import { Component, OnInit } from "@angular/core";
import { DeviceService } from "../service/device.service";
import { environment } from "src/environments/environment";
import { footerLinks } from "./interfaces";
import { footerLinksData } from "./footerLinksData";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isVisible: boolean = false;
  currentLanguage: string = '';
  links!: footerLinks;
  mondoConvUrl: string = '';
  mondoConvGroupUrl: string = ''
  blogMondoConvUrl: string = ''
  isMobile: boolean = false;

  constructor(
    public _detectorService: DeviceService,
    public _apiService: ApiService
  ) {
    this.isMobile = this._detectorService._isMobile.getValue();
  }

  ngOnInit(): void {
    const { magento, mondoconvgroup, blogmondoconv } = environment;
    this.mondoConvUrl = magento.it;
    this.mondoConvGroupUrl = mondoconvgroup;
    this.blogMondoConvUrl = blogmondoconv;
    this.links = (this.currentLanguage === 'it') ? footerLinksData.it : footerLinksData.es;

    document.addEventListener('scroll', () => {
      const PAGE_OFFESET = 300;
      if (window.pageYOffset > PAGE_OFFESET) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
