import { JsonObject, JsonProperty } from 'json2typescript';
import { Payment } from './Payment';

@JsonObject('PaymentEmail')
export class PaymentEmail extends Payment {
  @JsonProperty('cusr', String)
  public cusr: string = '500';
  @JsonProperty('codmcpv', String)
  public codmcpv: string = '';
  @JsonProperty('caparra', Number)
  public caparra: number = 1;
  @JsonProperty('telefono', String)
  public telefono: string = '';
  @JsonProperty('guidcli', String)
  public guidcli: string = '';
  @JsonProperty('intestatario', String)
  public intestatario: string = '';
}
