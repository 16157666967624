<p
*ngIf="geometra && isStatoRilievoMisure;"
  (click)="openModal($event, 'geometra')"
  class="mb-md-0 labelServices">
    {{ "services.txtRilievoMisure" | translate }}
</p>
<p
  *ngIf="geometra && !isStatoRilievoMisure"
  class="mb-md-0 labelServices">
  <a target="_blank" href="{{ url | translate }}">
    {{ "services.txtRilievoMisure" | translate }}
  </a>
</p>

