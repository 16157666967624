import {JsonConverter, JsonCustomConvert} from 'json2typescript';

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
  serialize(date: Date | null): string | null {
    if (date !== null) {
      return this.toIsoStringOffset(date);
    }
    return null;
  }

  deserialize(date: string): Date {
    return new Date(date);
  }

  private toIsoStringOffset = (date: Date): string => {
    const tzo = date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = (num: number) => {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      dif +
      pad(tzo / 60) +
      ':' +
      pad(tzo % 60)
    );
  };
}
