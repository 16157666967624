import { Component, OnInit } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { LoadingService } from '../service/loading.service';
import { Observable, Subject  } from 'rxjs';
import { RouteService } from '../service/route.service';
import { Voucher } from 'src/entities/voucher';
import { VouchersService } from '../service/vouchers.service';
import { ApiService } from '../service/api.service';


@Component({
  selector: 'app-buoni-spesa',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.scss'],
})
export class VouchersComponent implements OnInit {
  vouchers$!: Subject<Voucher[]>;
  loading$: Observable<boolean>;
  isMobile: boolean;
  constructor(
    private _detectorService: DeviceService,
    private _route: RouteService,
    private _vouchersService: VouchersService,
    public _apiService: ApiService,
    public _loadingService: LoadingService,
  ) {
    this.isMobile = this._detectorService._isMobile.getValue();
    this.loading$ = _loadingService.loading$;
  }

  ngOnInit(): void {
    this.vouchers$ = this._vouchersService.vouchers$;
  }

  cameBack() {
    this._route.back();
  }
}
