import { Component, OnInit } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { LOGO, LOGO_MOBILE } from 'src/config/constats';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss'],
})
export class LoginAdminComponent implements OnInit {
  currentUrl: string = '';
  isLoginBox: boolean = true;
  logoUrl: string = LOGO;
  logoMobileUrl: string = LOGO_MOBILE;
  image: string = '/assets/img/loginpage-image.png';
  privacyPolicy: string = '';
  cookiePolicy: string = '';
  urlServizi: string = '';
  isMobile: boolean = false;
  isWidth: boolean = false;
  mondoConv: string = '';

  constructor(
    public _translate: TranslateService,
    public _detectorService: DeviceService,
  ) {
    this.currentUrl = window.location.pathname;
    this.isMobile = this._detectorService._isMobile.getValue();
    this.isWidth = window.matchMedia('width: 1919px').matches;
    this.mondoConv = environment.magento.it;
  }

  ngOnInit() {}
}
