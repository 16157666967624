import { JsonObject, JsonProperty } from 'json2typescript';
import { PaymentOrder } from './PaymentOrder';

@JsonObject('Payment')
export class Payment {
  @JsonProperty('email', String)
  public email: string = '';
  @JsonProperty('pagamento', String) // UNIP - CC
  public pagamento: string = '';
  @JsonProperty('ordini', [PaymentOrder])
  public ordini: PaymentOrder[] = [];
}
