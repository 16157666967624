import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { sha256 } from 'js-sha256'
import { User } from 'src/entities/user';

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  constructor(
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  init() {}

  eventLogged(user: User, pathname: string) {
    this.pushEvents({
      event: "init",
      logged_in: true,
      pageName: pathname,
      userData: {
        user_id: user.id,
        user_type: "private",
        user_email: user.email,
        user_phone: undefined,
        user_firstname: user.firstname,
        user_lastname: user.lastname,
        user_birthdate: undefined,
        user_gender: undefined,
        user_address: "Via Manzoni 31",
        user_city_fb: undefined,
        user_city_gads: undefined,
        user_region: undefined,
        user_cap: undefined,
        user_country: "it",
        user_email_hash: this.convertStrToAsh(user.email),
        user_phone_hash: undefined,
        user_firstname_hash: this.convertStrToAsh(user.firstname),
        user_lastname_hash: this.convertStrToAsh(user.lastname),
        user_birthdate_hash: undefined,
        user_gender_hash: undefined,
        user_address_hash: "2ef5b4d16a205b75fa24645ee9f909eed9fcd6ca91e4c357aa7025a2c8f97be4",
        user_city_fb_hash: undefined,
        user_city_gads_hash: undefined,
        user_region_hash: undefined,
        user_cap_hash: "4ab86aa028daf73a58d5b58ede2a57c03e49fbe1b9c194e17015f53b03dd164c",
        user_country_hash: this.convertStrToAsh("it")
      }
    });
  }

  eventNotLogged(pathname:string) {
    this.pushEvents({
      event: "init",
      logged_in: false,
      pageName: pathname,
      userData: {
        user_id: undefined,
        user_type: undefined,
        user_email: undefined,
        user_phone: undefined,
        user_firstname: undefined,
        user_lastname: undefined,
        user_birthdate: undefined,
        user_gender: undefined,
        user_address: undefined,
        user_city_fb: undefined,
        user_city_gads: undefined,
        user_region: undefined,
        user_cap: undefined,
        user_country: undefined,
        user_email_hash: undefined,
        user_phone_hash: undefined,
        user_firstname_hash: undefined,
        user_lastname_hash: undefined,
        user_birthdate_hash: undefined,
        user_gender_hash: undefined,
        user_address_hash: undefined,
        user_city_fb_hash: undefined,
        user_city_gads_hash: undefined,
        user_region_hash: undefined,
        user_cap_hash: undefined,
        user_country_hash: undefined,
      }
    });
  }

  eventLogin(user: User) {
    this.pushEvents({
      event: "login",
      user_id: user.id,
      login_option: "manual",
      user_email: user.email,
      user_email_hash: this.convertStrToAsh(user.email)
    });
  }

  eventLoginFb(user: User, id:string) {
    this.pushEvents({
      event: "login",
      user_id: id,
      login_option: "facebook",
      user_email: user.email,
      user_email_hash: this.convertStrToAsh(user.email)
    });
  }

  eventLoginG(user: User, id:string) {
    this.pushEvents({
      event: "login",
      user_id: id,
      login_option: "google",
      user_email: user.email,
      user_email_hash: this.convertStrToAsh(user.email)
    });
  }

  eventLoginGuid() {
    this.pushEvents({
      event: "login",
      login_option: "codice",
    });
  }

  eventSignup(data: { email: string, firstname: string, lastname: string, id: number }) {
    this.pushEvents({
      event: "sign_up",
      userData: {
        user_id: data.id+"",
        user_type: "private",
        user_email: data.email,
        user_firstname: data.firstname,
        user_lastname: data.lastname,
        user_email_hash: this.convertStrToAsh(data.email),
        user_firstname_hash: this.convertStrToAsh(data.firstname),
        user_lastname_hash: this.convertStrToAsh(data.lastname),
      }
    });
  }

  eventBookAppointmentOrder(guid:string) {
    this.pushEvents({
      event: "book_appointment_order",
      category: "book_appointment",
      action: "order",
      label: guid
    });
  }

  eventBookAppointmentChange() {
    this.pushEvents({
      event: "appointment_changed",
      category: "book_appointment",
      action: "changed",
      start_point: "my_account"
    });
  }

  eventBookAppointmentCancelled() {
    this.pushEvents({
      event: "appointment_cancelled",
      category: "book_appointment",
      action: "cancelled",
      start_point: "my_account"
    });
  }

  eventInvoice() {
    this.pushEvents({
      event: "request_invoice",
    });
  }

  eventDownloadInvoice() {
    this.pushEvents({
      event: "file_download",
      file_name: "fattura",
      file_extension: "pdf",
    })
  }

  eventAssistance(action: string) {
    this.pushEvents({
      event: "request_assistance",
      category: "request_assistance",
      action: action
    });
  }

  eventPayByLink(isAdmin: boolean = false) {
    const label: string = isAdmin ? 'paga ora operatore' : 'paga ora';
    this.pushEvents({
      event: "click_cta",
      category: "click",
      action: "cta",
      label: label
    });
  }

  private pushEvents(data: object) {
    this.gtmService.pushTag(data);
  }

  private convertStrToAsh(data: string) {
    return sha256.update(data).hex();
  }
}
