import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, } from '@angular/forms';
import { AdminService } from '../service/admin.service';

@Component({
  selector: 'app-login-box-admin',
  templateUrl: './login-box-admin.component.html',
  styleUrls: ['./login-box-admin.component.scss'],
})
export class LoginBoxAdminComponent {
  @Output() changeLoginBox = new EventEmitter<boolean>();

  isMobile: boolean = false;
  loggedIn: boolean = false;
  loginAdminDataForm!: UntypedFormGroup;
  showModal: boolean = false;
  show_button: boolean = false;
  show_eye: boolean = false;

  host: string = "";
  loader: boolean = false;

  constructor(
    private _adminService: AdminService,
    private _fb: UntypedFormBuilder,
    private _translate: TranslateService,
    private route: Router,
    public _detectorService: DeviceService
  ) {
    this.initForm();

    switch (window.location.host) {
      case 'account-sg.mondoconv.it':
        this.host = 'https://staging2.mondoconv.it';
        break;

      case 'account.mondoconv.it':
        this.host = 'https://www.mondoconv.it';
        break;

      default:
        this.host = 'https://www.mondoconv.it';
        break;
    }

    this.loginAdminDataForm.controls['email'].valueChanges
      .subscribe(x => {
        if (x && x.includes(' ')) {
          this.loginAdminDataForm.controls['email'].setValue(x.trim())
        }
      })
    this.isMobile = this._detectorService._isMobile.getValue();
  }

  ngOnInit() { }

  onSubmit() {
    if (this.loginAdminDataForm.valid) {
      this._adminService.login(this.loginAdminDataForm.value.email.trim(), this.loginAdminDataForm.value.password).subscribe((response: any) => {
        if (response && response === 200) {
          this.loginAdminDataForm.reset();
          this.route.navigateByUrl('search');
        } else {
          this.changeShowModal();
        }
      });
    }
  }

  initForm() {
    this.loginAdminDataForm = this._fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

  changeShowModal() {
    this.showModal = !this.showModal;
  }
  closeModal() {
    this.showModal = false;
  }
}
