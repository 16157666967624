import { UrlService } from './url.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HeadersService } from './headers.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private headers: HttpHeaders;
  constructor(
    private http: HttpClient,
    private _urlService: UrlService,
    private _headersService: HeadersService
  ) {
    this.headers = this._headersService.getHeaders();
  }

  upload(data: any) {
    const url = this._urlService.getUrl('uploadAssistanceFiles');
    return this.http.post(url, data, {
      headers: this.headers,
      observe: `body`,
      responseType: 'json'
    })
  }
}
