import { AutoLoginVsMagento } from "./autoLoginVsMag.service";
import { BehaviorSubject, Subject, throwError, catchError, map, shareReplay } from "rxjs";
import { CustomCookieService } from "./customCookie.service";
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Order } from "src/entities/order";
import { Router } from "@angular/router";
import { UrlService } from "./url.service";
import { UserService } from "./user.service";
import { TokenService } from "./token.service";
import { HeadersService } from "./headers.service";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  headers: HttpHeaders;
  order$: BehaviorSubject<Order> = new BehaviorSubject<Order>(new Order);
  orderIsEmpty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  orders$: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>([]);
  shippingCost$: Subject<number> = new BehaviorSubject<number>(0);
  subtotal$: Subject<number> = new BehaviorSubject<number>(0);

  constructor(
    private _apiService: ApiService,
    private _autoLoginVsMagService: AutoLoginVsMagento,
    private _customCookieService: CustomCookieService,
    private _headersService: HeadersService,
    private _tokenService: TokenService,
    private _urlService: UrlService,
    private _userService: UserService,
    private http: HttpClient,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    this.headers = this._headersService.getHeaders();
  }

  /**
   * User Login
   * @param {string} email
   * @param {string} pwd
   * @return {number} status
   * @public
   */
  login(email: string, pwd: string) {
    const url = this._urlService.getUrl('login');

    return this.http
      .post(url, {
        username: email.trim(),
        password: pwd
      }, {
        headers: this.headers,
        observe: `body`,
        responseType: 'json'
      })
      .pipe(
        map((response: any) => {
          let status = 400;
          if (response) {
            const customer = response[0];
            const msg = response[1];
            status = response[2];
            if (status == 200) {
              this._userService.setUser(customer);
              this._userService.setFullname$.next(`${customer.firstname} ${customer.lastname}`);
              this._tokenService.setTypeToken('default');
              this._tokenService.setToken(customer.auth_token);
              this._apiService.isLogged$.next(true);
              this._autoLoginVsMagService.autoLoginSubject$.next({
                email,
                password: pwd
              });
            }
          }
          return status;
        }),
      );
  }

  /**
   * Login via user token
   * @param {string} token
   * @return {User} Return a object user and set islogged
   * @public
   */
  tokenLogin(token: string) {
    const url = this._urlService.getUrl('autoLogin');

    const headers = this.headers;

    return this.http
      .post(url, {
        token
      }, {
        headers,
        observe: `body`,
        responseType: 'text'
      })
      .pipe(
        map((response) => {
          if (!response) this.router.navigate(['/']);
          this._userService.setUser(JSON.parse(response));
          this._tokenService.setTypeToken('default');
          this._tokenService.setToken(token);
          this._userService.setFullname$.next(`${JSON.parse(response).firstname} ${JSON.parse(response).lastname}`);
          this._apiService.isLogged$.next(true);
        }),
        shareReplay(1),
        catchError((e) => {
          const err = JSON.parse(e.error);
          this._customCookieService.deleteCookie(['auth_token', "auth_token_sso"]);
          return throwError(() => new Error('Error Auth Login Token'));
        })
      );
  }
}
