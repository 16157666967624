import {
  Component,
  HostListener,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { ACCEPTED_STATE } from 'src/config/constats';
import { AdminService } from '../service/admin.service';
import { ApiService } from '../service/api.service';
import { AppointmentService } from '../service/appointment.service';
import { BehaviorSubject, Subject, tap } from 'rxjs';
import { Detail } from 'src/entities/detail';
import { DeviceService } from '../service/device.service';
import { exitSection } from 'src/config/menu';
import { FunzioneDisabilitataService } from '../service/modal/funzioneDisabilitata.service';
import { Order } from 'src/entities/order';
import { OrdiniService } from '../service/ordini.service';
import { PayByLinkService } from '../service/modal/payByLink.service';
import { PaymentModalService } from '../service/paymentModal.service';
import { Router } from '@angular/router';
import { RouteService } from '../service/route.service';
import { ServiceProductModalService } from '../service/serviceProductModal.service';
import { UtilityService } from '../service/utility.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  // Modificato encapsulation per rendere possibile l'override del css all'ngb-carousel
  encapsulation: ViewEncapsulation.None,
})
export class OrdersComponent implements OnInit {
  currentYear = new Date().getFullYear();
  orders: Order[] = [];
  groupOrders: _.Dictionary<any> = [];
  ordinaItems: Array<string> = [
    'Dal più recente',
    'Dal meno recente',
    // string(this.currentYear),
    'Ultimi 6 mesi'
  ];
  activeOrderBy: string = 'Dal più recente';
  typePopup: string = '';
  isMobile: boolean;
  isMobile2: boolean;
  showModal: boolean = false;
  states: string[] = [];
  isLogged: boolean = false;
  isGuest: boolean = false;
  iconExit: string = exitSection.icon;
  loader: boolean = false;
  isAdmin: Subject<boolean> = new BehaviorSubject<boolean> (false);
  currentOrder: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile2 = window.matchMedia('(max-width: 768px)').matches;
  }

  constructor(
    private _adminService: AdminService,
    private _apiService: ApiService,
    private _appointmentService: AppointmentService,
    private _detectorService: DeviceService,
    private _funzioneDisabilitataService: FunzioneDisabilitataService,
    private _payByLinkService: PayByLinkService,
    private _ordiniService: OrdiniService,
    private _routeService: RouteService,
    private _utilityService: UtilityService,
    private paymentModalService: PaymentModalService,
    private router: Router,
    private serviceProductModalService: ServiceProductModalService,
    private viewContainerRef: ViewContainerRef
  ) {
    this.isMobile = this._detectorService._isMobile.getValue();
    this.isMobile2 = window.matchMedia('(max-width: 768px)').matches;
    this.isLogged = this._apiService.isLogged$.getValue();
    this.isGuest = this._apiService.isGuest$.getValue();
    this.isAdmin = this._adminService.isAdmin$;
  }

  ngOnInit(): void {
    this.states = ACCEPTED_STATE;
  }

  ngAfterContentInit() {
    this._apiService.ordersObs$
      .pipe(
        tap((orders) => {
          if (
            !this._apiService.orderIsEmpty$.getValue() &&
            orders.length === 0
          ) {
            this.loader=true;
          } else {
            this.loader=false;
          }
        })
      )
      .subscribe((orders) => {
        this.groupOrders = this.groupByStato(orders);
        this.orders = orders;
      });
  }

  private groupByStato(orders: any) {
    if (orders.length === 0) return [];
    // this.loader = false;

    var obj = orders.reduce((acc: any, cur: any, i: any) => {
      acc[i] = cur;
      return acc;
    }, {});

    let objMerge: any;
    const countOrders = Object.keys(obj).length
    let countPartition = 0;

    const orderByConsegna = _.orderBy(obj, ['consegnaKey'], ['asc']);

    const { partitionDcc, partitionDapp, partitionDccp, partitionDcci } = this.partitionOrders(orderByConsegna);

    if (partitionDcc.length > 0) countPartition+= partitionDcc[0].length;
    if (partitionDapp.length > 0) countPartition+= partitionDapp[0].length;
    if (partitionDccp.length > 0) countPartition+= partitionDccp[0].length;
    if (partitionDcci.length > 0) countPartition+= partitionDcci[0].length;

    if (countOrders === countPartition) {
      const {
        groupByStatoDcc,
        groupByStatoDapp,
        groupByStatoDccp,
        groupByStatoDcci
      } = this.groupByOrders({ partitionDcc, partitionDapp, partitionDccp, partitionDcci });

      objMerge = _.merge(groupByStatoDcc, groupByStatoDapp, groupByStatoDccp, groupByStatoDcci);
      return objMerge;
    }

    return [];
  }

  private partitionOrders(orders: any) {
    const partitionDcc = _.partition(orders, 'dcc');
    const partitionDapp = (partitionDcc.length > 0 && partitionDcc[1].length > 0) ? _.partition(partitionDcc[1], 'dapp') : [];
    const partitionDccp = (partitionDapp.length > 0 && partitionDapp[1].length > 0) ? _.partition(partitionDapp[1], 'dccp') : [];
    const partitionDcci = (partitionDccp.length > 0 && partitionDccp[1].length > 0) ? _.partition(partitionDccp[1], 'dcci') : [];

    return {
      partitionDcc,
      partitionDapp,
      partitionDccp,
      partitionDcci,
    }
  }

  private groupByOrders({ partitionDcc, partitionDapp, partitionDccp, partitionDcci }: any) {
    let groupByStatoDcc;
    let groupByStatoDapp;
    let groupByStatoDccp;
    let groupByStatoDcci;

    if (partitionDcc.length > 0 && partitionDcc[0].length > 0) {
      groupByStatoDcc = this.groupByStatoField(partitionDcc, 'dcc');
    }

    if (partitionDapp.length > 0 && partitionDapp[0].length > 0) {
      groupByStatoDapp = this.groupByStatoField(partitionDapp, 'dapp');
    }

    if (partitionDccp.length > 0 && partitionDccp[0].length > 0) {
      groupByStatoDccp = this.groupByStatoField(partitionDccp, 'dccp');
    }

    if (partitionDcci.length > 0 && partitionDcci[0].length > 0) {
      groupByStatoDcci = this.groupByStatoField(partitionDcci, 'dcci');
    }

    return {
      groupByStatoDcc,
      groupByStatoDapp,
      groupByStatoDccp,
      groupByStatoDcci
    }
  }

  private groupByStatoField(partition: any[], field: string) {
    const groupByStato =_.groupBy(partition[0], 'stato');
      let returnObj = {};
      const valuesGroupByStato = Object.values(groupByStato);
      valuesGroupByStato.map((e) => {
        const groupByDate = _.groupBy(e, field);
        for (const [ key, value ] of Object.entries(groupByDate)) {
          const timestamp = self.crypto.getRandomValues(new Uint32Array(1));
          Object.assign(returnObj, { [`${timestamp[0]}`]: value });
        }
      })

      return returnObj;
    }


  sortBy(orderType: string): _.Dictionary<any> {
    this.activeOrderBy = orderType;
    var keys;
    switch (orderType) {
      case 'Dal più recente':
        keys = Object.values(this.groupOrders)
          .sort(
            (a, b) =>
              new Date(a[0].dbu).getTime() - new Date(b[0].dbu).getTime()
          )
          .reverse();

        var tempauxOrders: any = [];

        keys.forEach((k) => {
          tempauxOrders.push(k);
        });

        return tempauxOrders;
      case 'Dal meno recente':
        keys = Object.values(this.groupOrders).sort(
          (a, b) => new Date(a[0].dbu).getTime() - new Date(b[0].dbu).getTime()
        );

        var tempauxOrders: any = [];

        keys.forEach((k) => {
          tempauxOrders.push(k);
        });

        return tempauxOrders;
      /* case string(this.currentYear):
        const firstDayOfYear = new Date(this.currentYear, 1, 1);

        keys = Object.values(this.groupOrders).filter(
          (order) => new Date(order[0].dbu) > firstDayOfYear
        );
        var tempauxOrders: any = [];

        keys.forEach((k) => {
          tempauxOrders.push(k);
        });
        return tempauxOrders; */
      case 'Ultimi 6 mesi':
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        keys = Object.values(this.groupOrders).filter(
          (order) => new Date(order[0].dbu) > sixMonthsAgo
        );
        var tempauxOrders: any = [];

        keys.forEach((k) => {
          tempauxOrders.push(k);
        });
        return tempauxOrders;
      default:
        return this.groupOrders;
    }
  }
  getSum(array: any[], field: string, filter?: any, condition?: boolean) {
    const sum = array
      .filter((elem) => elem[filter] === condition)
      .map((elem) => elem[field])
      .reduce((a, b) => {
        if (field != 'saldo') {
          a = (a < 0) ? 0 : a;
          b = (b < 0) ? 0 : b;
        }
        return a + b;
      });
    return (sum <= 0) ? 0 : sum;
  }

  getSumProduct(dettagli: any) {
    let i = 0;
    dettagli.map((dettaglio: Detail) => {
      if (!dettaglio.trasporto) i++;
    })

    return i;
  }

  openModal(e: Event, order: Order, saldo: number) {
    e.preventDefault();
    this.paymentModalService.setRootViewContainerRef(this.viewContainerRef);
    this.paymentModalService.addDynamicComponent(order, saldo);
  }

  openModalGeometra(e: Event, type:string) {
    e.preventDefault();
    this.serviceProductModalService.setRootViewContainerRef(this.viewContainerRef);
    this.serviceProductModalService.addDynamicComponent(type);
  }

  openModalFunzioneDisabilitata(e: Event) {
    e.preventDefault();
    this._funzioneDisabilitataService.setRootViewContainerRef(this.viewContainerRef);
    this._funzioneDisabilitataService.addDynamicComponent();
  }

  openModalPayByLink(e: Event, order: Order) {
    let isAd:boolean = false;
    e.preventDefault();
    this.isAdmin.asObservable().subscribe((v) => {
      isAd = v;
    });
    this._payByLinkService.setRootViewContainerRef(this.viewContainerRef);
    this._payByLinkService.addDynamicComponent(order, isAd);
  }

  private dateTimezoneToStr(dateTmz: string | null): string {
    if (dateTmz == '' || dateTmz == null) return '';

    const [date, time]: string[] = dateTmz.split('T');
    return date.replaceAll('-', '');
  }

  goToOrder() {
    this._routeService.goTo('orders');
  }

  deleteReservation(order: Order) {
    this._appointmentService
      .cbkAppointment({
        guid: order.guid,
        shippingDate: this.dateTimezoneToStr(order.dcci),
        shippingType: order.consegnaKey,
        oldApp: this.dateTimezoneToStr(order.dapp),
        newApp: '',
      })
      .subscribe(() => {
        if (!this._apiService.isGuest$.getValue()) {
          this._ordiniService.cbkOrdini();
          this._apiService.isLogged$.next(true);
        } else {
          this._apiService.refreshCbkGuest();
        }
        this.openPopup('confirmDeleteAppointment');
      });
  }

  goToReservation(orderId: string) {
    this.router.navigate(['/reservation', orderId]);
  }

  changeShowModal() {
    this.showModal = !this.showModal;
  }

  closeModal() {
    this.showModal = false;
  }

  openPopup(type: string, order: [] = []) {
    this.typePopup = type;
    this.changeShowModal();
  }

  plusDays(date: string, days: number) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  exitMenu() {
    this._utilityService.exitMenu();
  }

  showButtonEditAppointment(
    statoKey:string,
    perapp: boolean,
    annullato: boolean,
    catesterno: boolean,
    dateutili:string,
    isCatanzaroDep: boolean,
    isRevisionWithAppointment: boolean
  ) {
    if (catesterno === undefined) catesterno = false;

    return this._utilityService.getShowEditAppointment(
      statoKey,
      perapp,
      annullato,
      catesterno,
      true,
      dateutili,
      isCatanzaroDep,
      isRevisionWithAppointment
    );
  }

  showButtonCreateAppointment(
    statoKey: string,
    perapp: boolean,
    annullato: boolean,
    catesterno: boolean,
    isCatanzaroDep:boolean,
    isRevisionWithAppointment: boolean
  ) {
    if (catesterno === undefined) catesterno = false;
    return this._utilityService.getShowCreateAppointment(
      statoKey,
      perapp,
      annullato,
      catesterno,
      true,
      isCatanzaroDep,
      isRevisionWithAppointment
    );
  }

  showTextForRevisionWithAppointment(
    statoKey: string,
    perapp: boolean,
    annullato: boolean,
    catesterno: boolean,
    isCatanzaroDep:boolean,
    isRevisionWithAppointment: boolean
  ) {
    if (catesterno === undefined) catesterno = false;
    const acceptedStateAppointment = (statoKey == ACCEPTED_STATE[9] || statoKey == ACCEPTED_STATE[4]);
    return acceptedStateAppointment && perapp && !annullato && !catesterno && !isCatanzaroDep && !isRevisionWithAppointment;
  }
}
