import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { section } from 'src/entities/section';
import { sectionsStructure, exitSection, searchSection } from './../../config/menu';
import { UtilityService } from '../service/utility.service';
import { AdminService } from '../service/admin.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  menu: Array<section> = sectionsStructure;
  exit: section = exitSection;
  search: section = searchSection;
  isMobile: boolean = false;
  isAdmin: Subject<boolean> = new BehaviorSubject<boolean> (false);
  @Output() closeMobileMenuEvent: EventEmitter<any> = new EventEmitter();
  constructor(
    private _utilityService: UtilityService,
    private _adminService: AdminService,
  ) {
    this.isMobile = window.matchMedia('(max-width: 768px)').matches;
  }

  ngOnInit(): void {
    this.isAdmin = this._adminService.isAdmin$;
  }

  closeMobileMenu() {
    this.closeMobileMenuEvent.emit();
  }

  exitMenu() {
    this._utilityService.exitMenu();
  }
}
