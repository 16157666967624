import { AdminService } from "./admin.service";
import { Appointment } from "src/entities/appointment";
import { AppointmentParams } from "src/entities/AppointmentParams";
import { BehaviorSubject, map, Observable, of, shareReplay, switchMap, take } from "rxjs";
import { HeadersService } from "./headers.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlService } from "./url.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  headers: HttpHeaders;
  private cbkAppointment$: BehaviorSubject<AppointmentParams>;
  appointment$: Observable<Appointment[]>;

  constructor(
    private _adminService: AdminService,
    private _headersService: HeadersService,
    private _urlService: UrlService,
    private _userService: UserService,
    private http: HttpClient
  ) {
    const isAdmin = this._adminService.isAdmin$.getValue();
    this.headers = this._headersService.getHeaders(isAdmin);
    this.cbkAppointment$ = new BehaviorSubject<AppointmentParams>({ guid: '', shippingDate: '', shippingType: '', oldApp: '', newApp: '' });
    this.appointment$ = this.cbkAppointment$.pipe(
      switchMap((params) => {
        if (!params.guid && !params.shippingDate && !params.shippingType && !params.oldApp && !params.newApp) return of([] as Appointment[]);
        const url = this._urlService.getUrl('appointment');
        const email = (this._userService.getEmail() && this._userService.getEmail() != '') ? this._userService.getEmail() : null;

        return this.http
          .post<Appointment[]>(url, {
            guid:params.guid,
            newApp:params.newApp,
            oldApp:params.oldApp,
            shippingDate:params.shippingDate,
            shippingType:params.shippingType,
            email
          }, {
            headers: this.headers,
            observe: `body`,
            responseType: 'json'
          })
      }),
      map((response: Appointment[]): Appointment[] => {
        return response;
      }),
      shareReplay(1),
      take(1)
    )
  }

  cbkAppointment(params: AppointmentParams) {
    this.cbkAppointment$.next(params);
    return this.appointment$;
  }
}

