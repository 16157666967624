<p
  *ngIf="isSdoppiato && !isSistemazione"
  (click)="openModal($event, 'parte')"
  class="mb-md-0 labelServices">
    {{ "services.txtParte" | translate }}
</p>
<p
  *ngIf="isRevisione && !isSistemazione && !catEsterno"
  (click)="openModal($event, 'revisione')"
  class="mb-md-0 labelServices">
    {{ "services.txtAssistenza" | translate }}
</p>
