// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  token: 'gpfAWpd29Cf8Qd5q6V6jPu5FMTZ2nb',
  recaptcha: {
    siteKey: '6Le931opAAAAAK8MIhTPmVrK7WQvN9G7Xwk5slbB',
  },
  middlewareIt: 'https://api.account-sg.mondoconv.it',
  middlewareEs: 'https://api.account-sg.mondoconv.it',
  frontend: 'account-sg.mondoconv.it',
  apiPaths: {
    appointment: '/api/ordermagw/edit-user-appointment',
    autoLogin: '/api/auth/magentoLoginSSO',
    autoLoginVsMagento: '/api/auth/auuLoginSSO',
    deleteAccount: '/api/account/delete', // areautente/delete
    editAccount: '/api/account/edit',  // areautente/personalData
    getCoordinates: '/api/account/coordinates', // areautente/coordinates
    getNewsletter: '/api/auth/magentoStatusNewsletter/', // areautente/newsletter/{{email}}
    img: '/api/product/download-image/',
    invoice: '/api/ordermagw/invoice/',
    login: '/api/auth/magentoLogin', // areautente/signin
    loginAdmin: '/api/auth/adminLogin',
    order: '/api/ordermagw/info/',
    orderByGuid: '/api/ordermagw/bcinfo/',
    requestAssistance: '/api/ordermagw/request-assistance',
    sendEmailPbl: '/api/pay/byLinkInit',
    signup: '/api/auth/magentoRegistration', // areautente/signup
    startUserPayment: '/api/pay/startUserPayment',
    subscribe: '/api/auth/magentoSubscribeNewsletter/', // V1/mc/newsletter/subscribe
    unsubscribe: '/api/auth/magentoUnsubscribeNewsletter', // V1/mc/newsletter/unubscribe
    uploadAssistanceFiles: '/api/ordermagw/upload-assistance-files'
  },
  optInApi: {
    token: 'https://mcjkx115wj2wwxbwrpl91f22bsn0.auth.marketingcloudapis.com/v2/token',
    optIn: 'https://mcjkx115wj2wwxbwrpl91f22bsn0.rest.marketingcloudapis.com/interaction/v1/events'
  },
  optInParams: {
    grant_type: "client_credentials",
    client_id: "prv0szeu8xlgf9j20mvrfdqm",
    client_secret: "PW5DNhaLO6IgTcv05t6CVoA9",
    account_id : "510001710"
  },
  magento: {
    it: 'https://staging2.mondoconv.it/',
    es: 'https://staging2.mondoconv.es/'
  },
  mondoconvgroup: 'https://www.mondoconvenienzagroup.com/',
  blogmondoconv: 'https://blog.mondoconv.it/',
  gmtCode: 'GTM-T8K3ST2'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
