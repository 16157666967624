<ng-template [ngIf]="currentUrl === '/login' ||
    currentUrl ==='/login?mode=guest' ||
    currentUrl === '/admin' ||
    currentUrl === '/signup' ||
    currentUrl === '/' ||
    currentUrl ==='/signup?mode=redirect' ||
    currentUrl.includes('=redirect')" [ngIfElse]="logged">
  <router-outlet></router-outlet>
</ng-template>

<ng-template #logged>
  <app-header (toggleMobileMenuEvent)="mobileMenuStateEvent($event)" [mobileMenuOpen]="mobileMenuState">
  </app-header>
  <div id="main_content" class="container" [ngClass]="{'guest-full-page': !(_apiService.isLogged$ | async)}">
    <div
      [ngClass]="{'justify-content-center': !(_apiService.isLogged$ | async), 'justify-content-between': (_apiService.isLogged$ | async)}"
      class="row gap-25">
      <div class="box_menu col-md-3 p-0 px-md-3" [ngClass]="{'active': mobileMenuState}"
        *ngIf="(_apiService.isLogged$ | async)">
        <app-menu (closeMobileMenuEvent)="closeMobileMenu()">
        </app-menu>
      </div>
      <div class="box_content p-0" [ngClass]="{'col-md-9': (_apiService.isLogged$ | async)}">
        <div id="buoniSpesa" class="mb-4" *ngIf="!(_apiService.isLogged$ | async)">
          <div class="row container">
            <div class="col-md-2 col-sm-6">
              <button class="btn btnDetail w-100" [ngClass]="currentUrl === '/orders' ? 'btn-blue-reverse' : 'btn-grey'"
                (click)="goToOrders()" name="btnDetail">
                {{ "menu.orders" | translate | uppercase }}
              </button>
            </div>
            <ng-container *ngIf="vouchersCounter$ | async as counterVouchers">
              <div *ngIf="counterVouchers > 0" class="col-md-2 col-sm-6">
                <button class=" btn btnDetail w-100"
                  [ngClass]="currentUrl === '/vouchers' ? 'btn-blue-reverse' : 'btn-grey'" (click)="goToVouchers()"
                  name="btnDetail">
                  {{ "menu.vouchers" | translate | uppercase }}
                  <span class="translate-middle badge rounded-pill bg-danger custom-badge">
                    {{ counterVouchers }}
                  </span>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
  <!-- <app-banner-bottom *ngIf="currentUrl !=='/login?mode=redirect'"></app-banner-bottom> -->
</ng-template>
<app-loader *ngIf="loading$ | async"></app-loader>
