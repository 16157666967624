import { Component, Input, OnInit } from '@angular/core';
import { Order } from 'src/entities/order';

@Component({
  selector: 'app-container-servizi-mc',
  templateUrl: './container-servizi-mc.component.html'
})

export class ContainerServiziMcComponent implements OnInit {
  @Input() data: Order | any;

  constructor() {}

  ngOnInit(): void {}
}
