<p
*ngIf="isTraghetto"
  (click)="openModal($event, 'traghetto')"
  class="mb-md-0 labelServices">
    {{ "services.txtTraghetto" | translate }}
</p>
<p
*ngIf="isAddebitoTrasporto"
  (click)="openModal($event, 'addebitoTrasporto')"
  class="mb-md-0 labelServices">
    {{ "services.txtAddebitoTrasporto" | translate }}
</p>
