import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-idraulico',
  templateUrl: './idraulico.component.html'
})

export class IdraulicoComponent implements OnInit {
  @Input() idraulico: boolean = false;

  url: string;

  constructor(
    private _translate: TranslateService
  ) {
    this.url = this._translate.instant('services.urlIdraulico');
  }

  ngOnInit(): void {}
}
