import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { Address } from 'src/entities/address';
import { User } from 'src/entities/user';
import { AddressService } from '../service/address.service';
import { LoadingService } from '../service/loading.service';
import { RouteService } from '../service/route.service';
import { UserService } from '../service/user.service';


@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
  addressInfo?: Address;
  addresses!: Observable<Address[]>
  user: User;
  constructor(
    private _addressService: AddressService,
    private router: Router,
    private _route: RouteService,
    private _loadingService: LoadingService,
    private _userService: UserService
  ) {
    this.user = this._userService.user$.getValue();
  }

  ngOnInit(): void {

    this.addresses = this._addressService.addressesObs$.pipe(
      tap((addresses) => {
        if (this._addressService.addressIsEmpty$.getValue() && addresses.length === 0) {
          this._loadingService.hide();
         }
      })
    );
  }

  goToDetailAddress(id: number) {
    this.router.navigate(['/addressDetail', id ]);
  }

  cameBack() {
    this._route.back();
  }

  /* changePrimary(id: any) {
    this.addresses.map((a) =>
      a.id === id ? (a.primary = true) : (a.primary = false)
    );
  } */
}
