import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Detail } from 'src/entities/detail';
import { ApiService } from '../service/api.service';
import { Order } from 'src/entities/order';
import { DeviceService } from '../service/device.service';

@Component({
  selector: 'app-card-product',
  templateUrl: './card-product.component.html',
  styleUrls: ['./card-product.component.scss'],
})
export class CardProductComponent implements OnInit {
  @Input() products: Detail[] = [];
  @Input() order!: Order;

  isMobile: boolean = false;
  grandTotal$: Subject<number>;
  shippingCost$: Subject<number>;
  subtotal$: Subject<number>;
  showDetails: boolean = false;

  constructor(
    private _apiService: ApiService,
    public _detectorService: DeviceService
  ) {
    this.grandTotal$ = this._apiService.grandTotal$;
    this.shippingCost$ = this._apiService.shippingCost$;
    this.subtotal$ = this._apiService.subtotal$;
    this.isMobile = this._detectorService._isMobile.getValue();
  }

  ngOnInit(): void {}
}



