import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProduct'
})
export class FilterProductPipe implements PipeTransform {

  transform(values: any[], ...args: unknown[]): any[] {
    const result = values.filter((v) => !v.trasporto);
    if ( result && result.length > 0 ){
      return result;
    } else {
        return [ -1 ];
    }
    // return values.filter(v => !v.trasporto && v.ccod);
  }
}
