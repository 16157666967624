import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { ServiceProductModalService } from '../../service/serviceProductModal.service';

@Component({
  selector: 'app-gestione-articolo',
  templateUrl: './gestione-articolo.component.html'
})

export class GestioneArticoloComponent implements OnInit {
  @Input() scorta: boolean = false;

  constructor(
    private serviceProductModalService: ServiceProductModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  openModal(e: Event, type:string) {
    e.preventDefault();
    this.serviceProductModalService.setRootViewContainerRef(this.viewContainerRef);
    this.serviceProductModalService.addDynamicComponent(type);
  }
}
