import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from './converter/date-converter';

@JsonObject('Voucher')
export class Voucher {
  @JsonProperty('dtemis', DateConverter)
  public dtemis!: Date;
  @JsonProperty('dtscad', DateConverter)
  public dtscad!: Date;
  @JsonProperty('idbuonosp', String)
  public idbuonosp: string = '';
  @JsonProperty('importo', Number)
  public importo: number = 0;
  @JsonProperty('impused', Number)
  public impused: number = 0;
  @JsonProperty('idbc', String)
  public idbc: string = '';
}
