<div id="help-section">
    <h2 class="mb-4">{{ "buttons.askAssistance" | translate }}</h2>
    <div role="button" class="d-flex col-12 align-items-center mb-3">
        <a target="_blank" [href]="'help.linkFAQ' | translate">
          <i class="bi bi-question-circle"></i>
        </a>
        <div>
            <p>
              <a target="_blank" [href]="'help.linkFAQ' | translate">
                {{ "help.txtFAQ" | translate }}
              </a>
            </p>
            <span>{{ "help.desFAQ" | translate }}</span>
        </div>
    </div>
</div>
