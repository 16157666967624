import { CodOperatoreService } from "./codOperatore.service";
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenService } from "./token.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class HeadersService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'authorizationToken': environment.token
  });

  constructor(
    private _codOperatore: CodOperatoreService,
    public _tokenService: TokenService,
  ) { }

  /**
   * get url
   * @return {HttpHeaders} Return a header.
   * @public
   */
  getHeaders(addInfoAdmin: boolean = false) {
    const token = this._tokenService.getToken();
    const type = this._tokenService.getTypeToken();
    const objHeaders = {
      'Content-Type': 'application/json',
      'authorizationToken': environment.token,
    };

    if (token) {
      Object.assign(objHeaders, { "typeLogin": type });
      Object.assign(objHeaders, { "customerToken": token });
    }

    if (addInfoAdmin) {
      Object.assign(objHeaders, { "X-User": this._codOperatore.getCode() });
    }

    return new HttpHeaders(objHeaders);
  }

  setHeaders(key: string, value: string) {
    return this.headers.append(key, value);
  }
}
