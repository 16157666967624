<div id="fissa_appuntamento" class="container">
  <div class="row mb-5">
    <div class="col-md-8 text-md-start text-sm-center text-sm-left-c">
      <h1 *ngIf="order.consegnaKey !== 'R'">
        <span class="border-bottom border-red border-3 pb-2 d-inline-block">{{ (((order.dapp === '' || order.dapp == null) ? "reservation.title" : "reservation.titleUpdate") | translate) }}</span>
      </h1>
      <h1 *ngIf="order.consegnaKey === 'R'">
        <span class="border-bottom border-red border-3 pb-2 d-inline-block">{{ "reservation.warehouseTitle" | translate }}</span>
      </h1>
    </div>
    <div class="col-md-4 text-md-end text-sm-start text-sm-right-c position-sm-absolute d-sm-block">
      <a (click)="cameBack()" class="btn-link">
        <i class="fa fa-arrow-left d-md-none text-red fz-25" aria-hidden="true"></i>
        <span class="d-md-block d-sm-none">< {{ "common.cameBack" | translate }}</span>
      </a>
    </div>
  </div>

  <form [formGroup]="reservationDataForm">
  <div class="row mb-3">
    <div class="col-12">
      <h2 class="text-green mb-3 font-family-montserrat fw-bold fs-sm-21">{{ "reservation.subtitleReservation" | translate }}</h2>
      <p class="par_2 fw-bold mb-3">{{
        (order.consegnaKey === 'R' ?
        "reservation.txtWarehouseOrdered" :
        ((order.dapp === '' || order.dapp == null) ? "reservation.txtOrdered" : "reservation.titleUpdate"))
        | translate | uppercase }}</p>
      <div class="datepicker input-group">
        <input
          ngbDatepicker
          #d="ngbDatepicker"
          class="form-control"
          name="dateReservation"
          (ngModelChange)="changeDate($event)"
          formControlName="dateReservation"
          [navigation]="navigation"
          [outsideDays]="outsideDays"
          [showWeekNumbers]="showWeekNumbers"
          [firstDayOfWeek]="firstDayOfWeek"
          [dayTemplate]="customDay"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [markDisabled]="isDisabled"
          [(ngModel)]="dapp"
        >
        <ng-template #customDay let-date="date" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
          <div class="custom-day btn-light" ngbdatepickerdayview
              [class.weekend]="isWeekend(date)"
              [class.focused]="focused"
              [class.outside]="date.month !== currentMonth"
              [class.bg-primary]="selected"
              [class.text-muted]="disabled"
          >
            {{ date.day }}
          </div>
        </ng-template>

        <button class="btn btn-black calendar" (click)="d.toggle()" type="button">
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row col-12 pb-md-5 pb-sm-4 border-bottom mb-5">
    <div class="col-6 mt-md-0 mt-sm-3 delApp" *ngIf="isMobile">
      <div class="col-6 mt-md-0 mt-sm-3">
        <button
          class="btn btn-green"
          name="btnConfirmAppointment"
          (click)="submitReservation()"
          [disabled]="isSameDate || !reservationDataForm.value.dateReservation">
          <span *ngIf="order.dapp == null">{{ 'buttons.confirm' | translate | uppercase }}</span>
          <span *ngIf="order.dapp != null">{{ 'buttons.editAppointment' | translate | uppercase }}</span>
        </button>
      </div>
      <div class="col-6 mt-md-0 mt-sm-3 ms-4" *ngIf="order.perann">
        <button class="btn btn-blue-reverse" name="btnDeleteAppointment"
          (click)="openPopup('deleteAppointment')">
            {{ 'buttons.deleteAppointment' | translate | uppercase }}
        </button>
      </div>
    </div>
    <div class="col-12 mb-3" *ngIf="order.consegnaKey !== 'R' && !isGuest">
      <p class="par_2 fw-bold mb-md-0 mb-sm-2">{{ "commons.txtAddressShipping" | translate | uppercase }}</p>
      <p class="par_2">{{ order.nom }} {{ order.cog }}</p>
      <p class="par_2">{{ order.ind }}, {{ order.civico }} - {{ order.comune }}</p>
      <p class="par_2">{{ order.cap }}</p>
    </div>
    <div class="col-12 mb-3 row" *ngIf="order.consegnaKey === 'R'">
      <div  [ngClass]="isMobile ? 'col-6': 'col-4'">
        <p class="par_2 fw-bold mb-md-0 mb-sm-2">{{ "reservation.txtWarehouse" | translate | uppercase }}</p>
        <p class="par_2">{{ order.nomeDep }}</p>
        <p class="par_2">{{ order.indDep }}</p>
        <p class="par_2">{{ order.comuneDep }}</p>
        <p class="par_2">
          <a class="textMaps" [href]="urlsWarehouseMaps" target="_blank">
            {{ 'reservation.googleMaps' | translate }}
          </a>
        </p>
      </div>
      <div [ngClass]="isMobile ? 'col-6': 'col-5'">
        <p class="par_2 fw-bold mb-md-0 mb-sm-2">{{ "reservation.txtDate" | translate | uppercase }}</p>
        <p class="par_2" [innerHTML]="order.orarioDep"></p>
      </div>
    </div>
    <div class="col-6 mt-md-0 mt-sm-3 delApp" *ngIf="!isMobile">
      <div class="col-6 mt-md-0 mt-sm-3">
        <button
          class="btn btn-green"
          name="btnConfirmAppointment"
          (click)="submitReservation()"
          [disabled]="isSameDate || !reservationDataForm.value.dateReservation">
          <span *ngIf="order.dapp == null">{{ 'buttons.confirm' | translate | uppercase }}</span>
          <span *ngIf="order.dapp != null">{{ 'buttons.editAppointment' | translate | uppercase }}</span>

        </button>
      </div>
      <div class="col-6 mt-md-0 mt-sm-3 ms-4" *ngIf="order.perann">
        <button class="btn btn-blue-reverse" name="btnDeleteAppointment"
          (click)="openPopup('deleteAppointment')">
            {{ 'buttons.deleteAppointment' | translate | uppercase }}
        </button>
      </div>
    </div>
    <div class="col-12 mt-sm-3 mb-md-2 pdf-box d-flex" *ngIf="order.consegnaKey === 'R'">
      <i class="bi bi-exclamation-triangle me-3"></i>
      <div class="row">
          <p>
              {{ "reservation.txtTitlePdf" | translate }}
          </p>
          <p>
              {{ "reservation.txtDescPdf" | translate }} <a download href="/assets/pdf/delega_ritiro_merci.pdf" target="_blank">{{ "reservation.download" | translate }}</a>
          </p>
      </div>
    </div>
    <div class="col-12 mt-sm-3 mb-md-2 pdf-box d-flex" *ngIf="order.consegnaKey === 'P'">
      <i class="bi bi-exclamation-triangle me-3"></i>
      <div class="row">
          <span>
              {{ "reservation.txtTitleConsegnaMontaggio" | translate }}
          </span>
          <span>
              {{ "reservation.txtTitleConsegnaMontaggioTwo" | translate }}
              <a (click)="openModalTM($event)">
                {{ "reservation.txtClickHere" | translate }}
              </a>
          </span>
      </div>
    </div>
  </div>
  </form>
</div>
<app-custom-modal *ngIf="showModal" (closeModal)="closeModal()" modalClass="mobile-height">
  <ng-container *ngIf="typePopup === 'deleteAppointment'">
    <div class="Modal-container">
      <h3 class="mb-3 text-center">{{ "reservation.titleDeleteReservation" | translate }}</h3>
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary w-100 mb-4" (click)="deleteReservation()" style="max-width: 360px;">
          {{ "buttons.deleteAppointment" | translate }}
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <span (click)="changeShowModal()" role="button"> {{ "common.cameBack" | translate }}</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="typePopup === 'confirmAppointment'">
    <div class="Modal-container text-center confirmAppointment" style="max-width: 700px;">
      <i class="bi bi-check-lg d-flex justify-content-center"></i>
      <p class="confirm mb-3">{{ "reservation.confirmTitle" | translate }}</p>
      <p class="mb-3">
        {{ "reservation.confirmRowAddress" | translate: {
          dapp: dateAppFormatterd,
          address: order.ind,
          civico: order.civico,
          cap: order.cap,
          comune: order.comune
        } }}
      </p>
      <p class="mb-3">
        {{ "reservation.confirmRow21" | translate }}
      </p>
      <p class="mb-3">
        {{ "reservation.confirmRow3" | translate }}
      </p>
      <div class="d-flex justify-content-center flex-row gap-4 mb-4" style="display: grid;">
        <div class="boxInfo">
          <img class="mb-2" src="/assets/img/appuntamento/ambienti.svg" />
          <p class="mb-3">{{ "reservation.confirmAmbienti" | translate }}</p>
        </div>
        <div class="boxInfo">
          <img class="mb-2" src="/assets/img/appuntamento/preseelettriche.svg" />
          <p class="mb-3">{{ "reservation.confirmPrese" | translate }}</p>
        </div>
      </div>
      <span class="back" (click)="goToOrder()">{{ "reservation.back" | translate }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="typePopup === 'confirmAppointmentGuest'">
    <div class="Modal-container text-center confirmAppointment" style="max-width: 700px;">
      <i class="bi bi-check-lg d-flex justify-content-center"></i>
      <p class="confirm mb-3">{{ "reservation.confirmTitle" | translate }}</p>
      <p class="mb-3">
        {{ "reservation.confirmRowAddressGuest" }}
      </p>
      <p class="mb-3">
        {{ "reservation.confirmRow21" | translate }}
      </p>
      <p class="mb-3">
        {{ "reservation.confirmRow3" | translate }}
      </p>
      <div class="d-flex justify-content-center flex-row gap-4 mb-4" style="display: grid;">
        <div class="boxInfo">
          <img class="mb-2" src="/assets/img/appuntamento/ambienti.svg" />
          <p class="mb-3">{{ "reservation.confirmAmbienti" | translate }}</p>
        </div>
        <div class="boxInfo">
          <img class="mb-2" src="/assets/img/appuntamento/preseelettriche.svg" />
          <p class="mb-3">{{ "reservation.confirmPrese" | translate }}</p>
        </div>
      </div>
      <span class="back" (click)="goToOrder()">{{ "reservation.back" | translate }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="typePopup === 'confirmAppointmentWarehouse'">
    <div
    class="Modal-container text-center confirmAppointment"
    style="max-width: 700px;">
    <div class="boxInfo">
      <img class="mb-2" src="/assets/img/appuntamento/pacco.svg" />
    </div>
    <p class="confirm mb-3">{{ "reservation.confirmWarehouseTitle" | translate }}</p>
    <p class="mb-3">
      {{ "reservation.confirmWarehouseAddress" | translate: {
        nome: order.nom,
        dapp: dateAppFormatterd,
        address: order.indDep,
        comune: order.comuneDep,
      } }}
    </p>
    <p class="mb-3">
      {{ "reservation.confirmWarehouseConditions" | translate }}
    </p>
    <div
      class="d-flex justify-content-center flex-row gap-4 mb-4 confirmAppointmentWarehouse">
      <div class="boxInfo warehouse">
        <img class="mb-2" src="/assets/img/appuntamento/consegna.png" />
        <p class="mb-3">{{ "reservation.confirmWarehouseSaldo" | translate }}</p>
      </div>
      <div class="boxInfo warehouse">
        <img class="mb-2" src="/assets/img/appuntamento/fattura.png" />
        <p class="mb-3">{{ "reservation.confirmWarehouseDoc" | translate }}</p>
      </div>
      <div class="boxInfo">
        <img class="mb-2" src="/assets/img/appuntamento/doc.png" />
        <p class="mb-3">{{ "reservation.confirmWarehouseDelega" | translate }}</p>
      </div>
    </div>
    <p class="mb-3">
      {{ "reservation.confirmWarehouseFooter" | translate }}
    </p>
    <span class="back" (click)="goToOrder()">{{ "reservation.back" | translate }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="typePopup === 'confirmDeleteAppointment'">
    <div class="Modal-container confirmAppointment">
      <i class="bi bi-check-lg d-flex justify-content-center"></i>
      <p class="confirm mb-3">{{ "reservation.msgConfirmDeleted" | translate }}</p>
      <span class="back" (click)="goToOrder()">{{ "reservation.back" | translate }}</span>
    </div>
  </ng-container>
</app-custom-modal>
