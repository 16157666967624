import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dolce-casa',
  templateUrl: './dolce-casa.component.html'
})

export class DolceCasaComponent implements OnInit {
  @Input() dolceCasa: boolean = false;

  url: string;

  constructor(
    private _translate: TranslateService
  ) {
    this.url = this._translate.instant('services.urlDolceCasa');
  }

  ngOnInit(): void {}
}
