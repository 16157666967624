<div id="list-vouchers" class="container">
  <div class="row" [ngClass]="isMobile ? 'mb-3' : 'mb-5'">
    <div class="col-md-6 text-md-start text-sm-center text-sm-left-c mb-md-0"
      [ngClass]="isMobile ? 'mb-sm-1' : 'mb-sm-4'">
      <h1 class="border-bottom border-red border-3 pb-2 d-inline-block">
        <img src="assets/img/iconeMenu/buono-big.png" class="icon-vouchers"/>
        <span>{{ "menu.vouchers" | translate }}</span>
      </h1>
    </div>
    <div class="col-md-6 text-md-end text-sm-start text-sm-right-c position-sm-absolute" *ngIf="_apiService.isLogged$ | async">
      <a (click)="cameBack()" class="btn-link">
        <i class="fa fa-arrow-left d-md-none text-red fz-25" aria-hidden="true"></i>
        <span class="d-md-block d-sm-none">
          < {{ "common.cameBack" | translate }}</span>
      </a>
    </div>
  </div>
  <ng-container *ngIf="vouchers$ | async as vouchers">
    <div *ngFor="let voucher of vouchers" class="single-voucher rounded shadow p-md-5 mb-2">
      <div class="container p-sm-0 pb-sm-0 no-border-mobile col-md-12 p-0">
        <div class="d-md-flex d-sm-block pb-md-1 pb-sm-0 mb-3 border-bottom col-md-12 p-0 justify-content-between">
          <div class="col-md-12 pl-md-0 pr-md-3 px-sm-0">
            <p class="par_2 fw-semibold mb-1 row-buono">
              {{ "vouchers.txtId" | translate | uppercase }}: {{ voucher.idbuonosp }}
            </p>
          </div>
        </div>
        <div class="row mb-2 mb-md-0 mt-sm-0 mx-sm-0 border-bottom no-border-desk">
          <div class="d-md-flex d-sm-block col-md-9 p-0 justify-content-between custom-class-voucher"
            *ngIf="!isMobile">
            <div class="col-md-3 pl-md-0 pr-md-3 px-sm-0 width-date-voucher-column">
              <p class="par_2 fw-semibold mb-1">
                {{ 'vouchers.txtIdbc' | translate | uppercase }}
              </p>
            </div>
            <div class="col-md-3 pl-md-0 pr-md-3 px-sm-0 width-date-voucher-column">
              <p class="par_2 fw-semibold mb-1">
                {{ 'vouchers.txtDate' | translate | uppercase }}
              </p>
            </div>
            <div class="col-md-3 px-sm-0 width-date-voucher-column">
              <p class="par_2 fw-semibold mb-1">
                {{ 'vouchers.txtExpire' | translate | uppercase }}
              </p>
            </div>
            <div class="col-md-3 px-sm-0 width-date-voucher-column">
              <p class="par_2 fw-semibold mb-1">
                {{ 'vouchers.txtImport' | translate | uppercase }}
              </p>
            </div>
            <div class="col-md-3 px-sm-0 width-date-voucher-column">
              <p class="par_2 fw-semibold mb-1">
                {{ 'vouchers.txtUsedImport' | translate | uppercase }}
              </p>
            </div>
          </div>
          <div
            class="d-md-flex d-sm-block no-border-mobile col-md-9 p-0 justify-content-between custom-class-voucher"
            *ngIf="!isMobile">
            <div class="col-md-2 pl-md-0 pr-md-3 px-sm-0 width-date-voucher-column">
              <p class="par_2 mb-1">
                {{ voucher.idbc }}
              </p>
            </div>
            <div class="col-md-2 pl-md-0 pr-md-3 px-sm-0 width-date-voucher-column">
              <p class="par_2 mb-1">
                {{ voucher.dtemis | date:'dd-MM-yyyy' }}
              </p>
            </div>
            <div class="col-md-2 px-sm-0 width-date-voucher-column">
              <p class="par_2 mb-1">
                {{ voucher.dtscad | date:'dd-MM-yyyy'}}
              </p>
            </div>
            <div class="col-md-1 px-sm-0 width-date-voucher-column">
              <p class="par_2 mb-1">
                {{ voucher.importo | currency:'EUR'}}
              </p>
            </div>
            <div class="col-md-1 px-sm-0 width-date-voucher-column">
              <p class="par_2 mb-1">
                {{ voucher.impused | currency:'EUR'}}
              </p>
            </div>

          </div>

          <!-- BEGIN template mobile -->
          <div class="d-md-flex d-sm-block col-md-12 p-0 justify-content-between" *ngIf="isMobile">
            <div class="row mb-1">
              <div class="col-5 px-md-3 px-sm-0">
                <p class="par_2 fw-semibold mb-1">
                  {{ 'vouchers.txtIdbc' | translate | uppercase }}
                </p>
              </div>
              <div class="col-7 px-md-3 px-sm-0">
                <p class="par_2 fw-semibold mb-1">
                  {{ voucher.idbc}}
                </p>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-5 px-md-3 px-sm-0">
                <p class="par_2 fw-semibold mb-1">
                  {{ 'vouchers.txtDate' | translate | uppercase }}
                </p>
              </div>
              <div class="col-7 px-md-3 px-sm-0">
                <p class="par_2">
                  {{ voucher.dtemis | date:'dd-MM-yyyy' }}
                </p>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-5 px-md-3 px-sm-0">
                <p class="par_2 fw-semibold mb-1">
                  {{ 'vouchers.txtExpire' | translate | uppercase }}
                </p>
              </div>
              <div class="col-7 px-md-3 px-sm-0">
                <p class="par_2 fw-semibold mb-1">
                  {{ voucher.dtscad | date:'dd-MM-yyyy'}}
                </p>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-5 px-md-3 px-sm-0">
                <p class="par_2 fw-semibold mb-1">
                  {{ 'vouchers.txtImport' | translate | uppercase }}
                </p>
              </div>
              <div class="col-7 px-md-3 px-sm-0">
                <p class="par_2">
                  {{ voucher.importo | currency:'EUR'}}
                </p>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-5 px-md-3 px-sm-0" style="display: inline-flex;">
                <p class="par_2 fw-semibold mb-1">
                  {{ 'vouchers.txtUsedImport' | translate | uppercase }}
                </p>
              </div>
              <div class="col-7 px-md-3 px-sm-0">
                <p class="par_2">
                  {{ voucher.impused | currency:'EUR'}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<app-loader *ngIf="loading$ | async"></app-loader>
