import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class CodOperatoreService {

  public cod$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {}

  setCode(cod: string) {
    this.cod$.next(cod);
  }

  getCode(): string {
    return this.cod$.getValue();
  }
}

