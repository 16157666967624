import { Component, Input, OnInit } from '@angular/core';
import { Order } from 'src/entities/order';

@Component({
  selector: 'app-order-detail-address',
  templateUrl: './order-detail-address.component.html',
  styleUrls: ['./order-detail-address.component.scss'],
})
export class OrderDetailAddressComponent implements OnInit {
  @Input() order!: Order;
  @Input() isMobile: boolean = false;
  @Input() urlsWarehouseMaps: string = '';

  existAddressShipping: boolean = false;

  isAddress:boolean = false;
  isAddressWarehouse:boolean = false;
  isAddressStore:boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.order.ind) this.existAddressShipping = true
    this.isAddress = this.order.consegnaKey !== 'R' && !this.order.navetta && this.existAddressShipping;
    this.isAddressWarehouse = this.order.consegnaKey === 'R' && !this.order.navetta;
    this.isAddressStore = this.order.consegnaKey === 'N' && this.order.navetta;
  }
}
