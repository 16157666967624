import { Component, OnInit } from '@angular/core';
import { section } from 'src/entities/section';
import { User } from 'src/entities/user';
import { sectionsStructure } from '../../config/menu';
import { UserService } from '../service/user.service';
import { DeviceService } from '../service/device.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  elements: Array<section> = sectionsStructure;
  dashboard: Array<section> = [];
  user: User;
  desUser: string = '';
  isMobile: boolean = false;

  constructor(
    private _userService: UserService,
    private _deviceService: DeviceService,
  ) {
    this.user = this._userService.user$.getValue();
    this.desUser = `${this.user.firstname} ${this.user.lastname} ${this.user.email}`
    this.dashboard = this.elements.filter((e) => { return !e.allView; })
    this.isMobile = this._deviceService._isMobile.getValue();
  }

  ngOnInit(): void { }

}


