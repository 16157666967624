import { BehaviorSubject, Observable, of, retry, switchMap } from 'rxjs';
import { HeadersService } from './headers.service';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlService } from './url.service';
import { UserService } from "./user.service";
import { isEmpty } from "lodash";

@Injectable({
  providedIn: 'root'
})

export class NewsletterService {
  private cbkNewsletter$: BehaviorSubject<string>;
  newsletterStatus$: Observable<boolean>;

  constructor(
    private _headersService: HeadersService,
    private _urlService: UrlService,
    private _userService: UserService,
    private http: HttpClient
  ) {
    this.cbkNewsletter$ = new BehaviorSubject<string>('');
    this.newsletterStatus$ = this.cbkNewsletter$.pipe(
      switchMap((email:string) => {
        if (isEmpty(email)) return of(false);
        const url = `${this._urlService.getUrl('getNewsletter')}${email}`;

        return this.http
          .get<boolean>(url, {
            headers: this._headersService.getHeaders(true),
            observe: `body`,
            responseType: 'json'
          })
      }),
      retry(1),
    )
  }

  /**
  * Set value for newsletter
  * @param {string} email
  * @return {boolean} true | false
  * @public
  */
  subscribeNewsletter(email: string) {
    const url = `${this._urlService.getUrl('subscribe')}${email}`;

     return this.http
       .get(url, {
         headers: this._headersService.getHeaders(true),
         observe: `body`,
         responseType: 'text'
       })
  }

  unsubscribeNewsletter() {
    const email = this._userService.getEmail();
    const url = this._urlService.getUrl('unsubscribe');

    if (!email) return of ('');
    return this.http
       .post(url,{
        email
      }, {
         headers: this._headersService.getHeaders(true),
         observe: `body`,
         responseType: 'text'
       })
  }


  /**
  * Fn for refresh newsletter api
  * @return {Observable} refresh newsletter api.
  * @public
  */
  cbkNewsletter(email:string) {
    this.cbkNewsletter$.next(email);
  }

}
