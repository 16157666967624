import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('PaymentOrder')
export class PaymentOrder {
  @JsonProperty('idbc', String)
  public idbc: string = '';
  @JsonProperty('importo_pagato', Number)
  public importo_pagato: number = 0;
  @JsonProperty('importo_da_pagare', Number)
  public importo_da_pagare: number = 0;
  @JsonProperty('importo_bc', Number)
  public importo_bc: number = 0;
  @JsonProperty('dbu', String)
  public dbu: string = '';
  @JsonProperty('dcci', String)
  public dcci: string = '';
  @JsonProperty('impgeo', Number)
  public impgeo: number = 0;
}
