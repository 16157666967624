import { BehaviorSubject, Observable, of, Subject, throwError, catchError, map, retry, switchMap, tap } from "rxjs";
import { Detail } from "src/entities/detail";
import { DomSanitizer } from '@angular/platform-browser';
import { GuestService } from "./guest.service";
import { HeadersService } from "./headers.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingService } from "./loading.service";
import { Order } from "src/entities/order";
import { OrdiniUtiltyService } from "./ordiniUtility.service";
import { UrlService } from "./url.service";
import { User } from "src/entities/user";
import { UserService } from "./user.service";
import { UtilityService } from "./utility.service";
import { Voucher } from "src/entities/voucher";
import { VouchersService } from "./vouchers.service";

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  guestObs$: Observable<number>;
  isLoggedObs$: Observable<any>;
  orderObs$: Observable<Order>;
  ordersObs$: Observable<Order[]>;

  buoniSpesa: Voucher[] = [];
  grandTotal$: Subject<number> = new BehaviorSubject<number>(0);
  headers: HttpHeaders;
  isGuest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  cbkGuest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLogged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  order$: BehaviorSubject<Order> = new BehaviorSubject<Order>(new Order);
  orderIsEmpty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  orders$: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>([]);
  shippingCost$: Subject<number> = new BehaviorSubject<number>(0);
  subtotal$: Subject<number> = new BehaviorSubject<number>(0);

  constructor(
    private _guestService: GuestService,
    private _headersService: HeadersService,
    private _loadingService: LoadingService,
    private _ordiniUtility: OrdiniUtiltyService,
    private _urlService: UrlService,
    private _userService: UserService,
    private _utilityService: UtilityService,
    private _vouchersService: VouchersService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    let orderWithRevision = new Map<string, number>();

    this.headers = this._headersService.getHeaders();
    this.ordersObs$ = this.orders$.asObservable();
    this.orderObs$ = this.order$.asObservable().pipe(
      tap((order) => {
        let tot = 0;
        let spedizione = 0;
        order.dettagli.forEach((p) => {
          if (p.trasporto) {
            this.shippingCost$.next(p.importo);
            spedizione = p.importo;
          }

          if (p.ccod && !this.isGuest$.getValue()) {
            tot += order.totale;
          } else {
            tot += p.importo;
          }
        })

        const subtotal = tot - spedizione;
        this.shippingCost$.next(spedizione);
        this.subtotal$.next(subtotal);
        this.grandTotal$.next(tot);
      }));

    this.isLoggedObs$ = this.isLogged$.pipe(
      switchMap((isLogged) => {
        if (!isLogged) return of('');
        this.isGuest$.next(false);
        return this.getOrdini();
      })
    );

    this.guestObs$ = this.cbkGuest$.pipe(
      switchMap((isGuest) => {
        const guid = this._guestService.getGuid();
        if (!isGuest && !guid) return of(400);
        const cleanGuid: string = guid.trim();
        const url = `${this._urlService.getUrl('orderByGuid')}${cleanGuid}`;
        return this.http
          .get<any>(url, { headers: this._headersService.getHeaders() });
      }),
      map((response: Order[]) => {
        if (response.length > 0) {
          response.forEach((oe: any) => {
            if (oe.buonispesa.length > 0) {
              this.setVouchers(oe.buonispesa, `${oe.prbu}${oe.rev}`);
            }
            oe.statoKey = oe.stato;
            oe.consegnaKey = oe.consegna;
            oe.guid = this._guestService.getGuid();
            if (oe.dettagli.length === 0) oe.dettagli = new Array(new Detail);

            this._utilityService.updateObjValue(oe, orderWithRevision);
          });

          if(orderWithRevision.size > 0) this._ordiniUtility.iterationOrderForRevision(orderWithRevision, response);
          if (this.buoniSpesa.length > 0) this._vouchersService.setVouchers$.next(this.buoniSpesa);
          const obj: Order[] = response;
          this.orders$.next(obj);
          this.isGuest$.next(!this.isLogged$.getValue());
          this._guestService.setGuid(this._guestService.getGuid());
          return 200;
        }
        this._guestService.setGuid('');
        return 400;
      }),
      map((response: number) => {
        return response;
      }),
      catchError((e) => {
        this._loadingService.hide();
        return throwError(() => e.error);
      })
    );
  }

  refreshCbkGuest() {
    this.cbkGuest$.next(true);
  }

  getOrdini(emailToSearch: string = '', isAdmin: boolean = false) {
    if (isAdmin) {
      const userToSearch = new User;
      userToSearch.email = emailToSearch;
      this._userService.setUser(userToSearch);
    };

    if (!this._userService.user$.getValue().email) return of();

    const url = `${this._urlService.getUrl('order')}${this._userService.user$.getValue().email}`;
    const headers: HttpHeaders = this._headersService.getHeaders(true);
    return this.http
      .get<any>(url, { headers })
      .pipe(
        tap((response: any) => {
          if (!response || response.length === 0) {
            this.orderIsEmpty$.next(true);
            this.orders$.next([]);
          } else {
            const obj: Order[] = this._ordiniUtility.refactoringObjOrder(response);

            obj.map((o) => {
              if (o.buonispesa.length > 0) {
                this.setVouchers(o.buonispesa, `${o.prbu}${o.rev}`);
              }
              this._ordiniUtility.iterateDettaglio(o);
            });

            if (this.buoniSpesa.length > 0) this._vouchersService.setVouchers$.next(this.buoniSpesa);
            /* if (obj.length === 0) {
              this.orderIsEmpty$.next(true);
              this.orders$.next([]);
            } else {
              this.orders$.next(obj);
            } */
            this.orders$.next(obj);
          }

          this._loadingService.hide();
        }),
        retry(1))
  }

  setVouchers(buoniSpesa: Voucher[], bc:string) {
    buoniSpesa.map((voucher: Voucher) => {
      voucher.idbc = bc;
      this.buoniSpesa.push(voucher);
    })
  }
}
