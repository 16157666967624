import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { ServiceProductModalService } from '../../service/serviceProductModal.service';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-parte-ordine',
  templateUrl: './parte-ordine.component.html'
})

export class ParteOrdineComponent implements OnInit {
  @Input() rev: string = '';
  @Input() isSistemazione: boolean = false;
  @Input() catEsterno: boolean = false;

  isSdoppiato: boolean = false;
  isRevisione: boolean = false;
  typeModalService: string = '';

  constructor(
    private serviceProductModalService: ServiceProductModalService,
    private viewContainerRef: ViewContainerRef,
    private _utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.isSdoppiato = this._utilityService.checkIsSdoppiato(this.rev);
    this.isRevisione = this._utilityService.checkIsRevisione(this.rev);

    if (this.isRevisione) this.typeModalService = 'revisione';
    if (this.isSdoppiato) this.typeModalService = 'parte';

  }

  openModal(e: Event, type:string) {
    e.preventDefault();
    this.serviceProductModalService.setRootViewContainerRef(this.viewContainerRef);
    this.serviceProductModalService.addDynamicComponent(type);
  }
}
