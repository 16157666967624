import { JsonObject, JsonProperty } from 'json2typescript';
import { Address } from './address';
import { DateConverter } from './converter/date-converter';

@JsonObject('User')
export class User {
  @JsonProperty('id', String)
  public id: string = '';
  @JsonProperty('group_id', Number)
  public group_id: number = 0;
  @JsonProperty('created_at', DateConverter)
  public created_at?: string | null = '';
  @JsonProperty('updated_at', DateConverter)
  public updated_at?: string | null = '';
  @JsonProperty('created_in', DateConverter)
  public created_in?: string | null = '';
  @JsonProperty('email', String)
  public email: string = '';
  @JsonProperty('firstname', String)
  public firstname: string = '';
  @JsonProperty('lastname', String)
  public lastname: string = '';
  @JsonProperty('store_id', Number)
  public store_id: number = 1;
  @JsonProperty('website_id', Number)
  public website_id: number = 1;
  @JsonProperty('addresses', Array)
  public addresses?: Address[] = [];
  @JsonProperty('sfId', String)
  public sfId?: string = '';
  @JsonProperty('authToken', String)
  public authToken?: string = '';
}
