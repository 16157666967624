import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServiceProductModalService } from '../../service/serviceProductModal.service';
import { ACCEPTED_STATE } from 'src/config/constats';

@Component({
  selector: 'app-geometra',
  templateUrl: './geometra.component.html'
})

export class GeometraComponent implements OnInit {
  @Input() geometra: boolean = false;
  @Input() statoKey: string = '';

  isStatoRilievoMisure: boolean = false;

  url: string;

  constructor(
    private _translate: TranslateService,
    private serviceProductModalService: ServiceProductModalService,
    private viewContainerRef: ViewContainerRef
  ) {
    this.url = this._translate.instant('services.urlRilievoMisure');
    this.isStatoRilievoMisure = this.statoKey == ACCEPTED_STATE[1];
  }

  ngOnInit(): void {}

  openModal(e: Event, type:string) {
    e.preventDefault();
    this.serviceProductModalService.setRootViewContainerRef(this.viewContainerRef);
    this.serviceProductModalService.addDynamicComponent(type);
  }
}
