export const footerLinksData = {
  it: {
    areaUtente: "https://account.mondoconv.it",
    servizioClienti: {
      title: "servizio-clienti",
      cta1: "modalita-pagamento/metodi-di-pagamento",
      cta2: "consegna-montaggio/trasporto-e-montaggio",
      cta3: "garanzie/-le-nostre-garanzie",
      cta4: "servizio-clienti#servizi",
      cta5: "servizio-clienti#contatti",
      cta6: "resi-e-rimborsi",
      cta7: "i-tuoi-diritti",
      cta8: "faq",
    },
    acquistacomeVuoi: {
      title:"catalogo",
      cta1: "come-acquistare/presso-i-punti-vendita",
      cta2: "catalogo",
      cta3: "punti-vendita",
      cta4: "come-acquistare/progetto-dolce-casa",
      cta5: "appuntamento-venditore/index/index/step-shop",
      cta6: "prenotazione-videoconferenza/index/index/step-room",
      cta7: "modalita-pagamento/bonus-mobili-elettrodomestici",
      cta8: "newsletter-mondo-convenienza"
    },
    azienda: {
      cta1: "valori",
      cta2: "la-storia",
      cta3: "la-filiera",
      cta4: "innovazione-digitale",
      cta6: "modello-d-lgs-231-2001",
      cta7: "procedura-whistleblowing",
      cta8: "impegni-agcm",
      cta9: "obblighi-informativi-per-erogazioni-pubbliche"
    },
    social: {
      facebook: "https://www.facebook.com/mondoconv",
      pinterest: "https://it.pinterest.com/mondoconv",
      tiktok: "https://www.tiktok.com/@mondoconvenienza",
      youtube: "https://www.youtube.com/user/mondoconvenienzatv",
      instagram: "https://www.instagram.com/mondoconvenienza",
    },
    middleFooter: {
      payment: "modalita-pagamento/metodi-di-pagamento",
      lavoraConNoi: "posizioni-aperte"
    },
    subFooter: {
      cta1: "come-acquistare/condizioni-di-vendita",
      cta2: "riepilogo-note-mc",
      cta3: "sitemap",
      cta4: "privacy",
      cta5: "cookies",
      cta6: "https://mondoconvenienza.bravosolution.com/web/login.html",
    }
  },
  es: {
    areaUtente: "https://account.mondoconv.it",
    servizioClienti: {
      title: "servizio-clienti",
      cta1: "modalita-pagamento/metodi-di-pagamento",
      cta2: "consegna-montaggio/trasporto-e-montaggio",
      cta3: "garanzie/-le-nostre-garanzie",
      cta4: "servizio-clienti#servizi",
      cta5: "servizio-clienti#contatti",
      cta6: "resi-e-rimborsi",
      cta7: "i-tuoi-diritti",
      cta8:"faq",
    },
    acquistacomeVuoi: {
      title:"catalogo",
      cta1: "come-acquistare/presso-i-punti-vendita",
      cta2: "catalogo",
      cta3: "punti-vendita",
      cta4: "come-acquistare/progetto-dolce-casa",
      cta5: "appuntamento-venditore/index/index/step-shop",
      cta6: "prenotazione-videoconferenza/index/index/step-room",
      cta7: "modalita-pagamento/bonus-mobili-elettrodomestici",
      cta8: "newsletter-mondo-convenienza"
    },
    azienda: {
      cta1: "valori",
      cta2: "la-storia",
      cta3: "la-filiera",
      cta4: "innovazione-digitale",
      cta6: "modello-d-lgs-231-2001",
      cta7: "procedura-whistleblowing",
      cta8: "impegni-agcm",
      cta9: "obblighi-informativi-per-erogazioni-pubbliche"
    },
    social: {
      facebook: "https://www.facebook.com/mondoconv",
      pinterest: "https://it.pinterest.com/mondoconv",
      tiktok: "https://www.tiktok.com/@mondoconvenienza",
      youtube: "https://www.youtube.com/user/mondoconvenienzatv",
      instagram: "https://www.instagram.com/mondoconvenienza",
    },
    middleFooter: {
      payment: "modalita-pagamento/metodi-di-pagamento",
      lavoraConNoi: "posizioni-aperte"
    },
    subFooter: {
      cta1: "come-acquistare/condizioni-di-vendita",
      cta2: "riepilogo-note-mc",
      cta3: "sitemap",
      cta4: "privacy",
      cta5: "cookies",
      cta6: "https://mondoconvenienza.bravosolution.com/web/login.html",
    }
  },
};
