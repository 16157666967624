import { ApiService } from '../service/api.service';
import { Component, EventEmitter, Output } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { GtmService } from '../service/gtm.service';
import { GuestService } from '../service/guest.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Router } from '@angular/router';
import { RouteService } from '../service/route.service';
import { map, switchMap, tap } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-code-guest-box',
  templateUrl: './code-guest-box.component.html',
  styleUrls: ['./code-guest-box.component.scss'],
})
export class CodeGuestBoxComponent {
  guestBoxForm!: UntypedFormGroup;
  show_button: boolean = false;
  show_eye: boolean = false;
  showModal: boolean = false;
  isMobile: boolean = false;
  typePopup:string = '';

  @Output() changeLoginBox = new EventEmitter<boolean>();

  constructor(
    private _apiService: ApiService,
    private _fb: UntypedFormBuilder,
    private _gtmService: GtmService,
    private _guestService: GuestService,
    private _routeService: RouteService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: Router,
    public _detectorService: DeviceService,
  ) {
    this.initForm();

    this.guestBoxForm.controls['controlCode'].valueChanges
    .subscribe(x=>{
      if(x && x.includes(' ')){
        this.guestBoxForm.controls['controlCode'].setValue(x.trim())
      }
    });

    this.isMobile = this._detectorService._isMobile.getValue();

  }

  ngOnInit() {}

  onSubmit() {
    if (this.guestBoxForm.valid) {
      let res:number = 0;
      this._guestService.setGuid(this.guestBoxForm.value.controlCode);
      this._apiService.guestObs$.pipe(
        switchMap((response: number) => {
          res = response;
          return this.recaptchaV3Service.execute('login');
        }),
        map((token: string) => {
          if (!token) this.route.navigate(['/login']);
          return res;
        }),
        tap((response:number) => {
          if (response === 200) {
            this._gtmService.eventLoginGuid();
            if(window.location.pathname.includes('login')) {
              this.guestBoxForm.controls['controlCode'].setErrors({'incorrect': true});
              this.route.navigate(['/orders'])
            };
          } else {
            this.openPopup('wrongGuid');
          }
        })
      ).subscribe();
    }
  }

  initForm() {
    this.guestBoxForm = this._fb.group({
      controlCode: ['', [Validators.required, Validators.pattern("^[A-Z0-9]*$")]],
    });
  }
  setLoginBox(value: boolean) {
    this.changeLoginBox.emit(value);
  }
  goToSignUp() {
    this._routeService.goTo('signup');
  }

  openPopup(type: string) {
    this.typePopup = type;
    this.changeShowModal();
  }

  changeShowModal() {
    this.showModal = !this.showModal;
  }
  closeModal() {
    this.showModal = false;
  }

}
