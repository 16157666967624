import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, take } from 'rxjs';
import { User } from 'src/entities/user';
import { NewsletterService } from '../service/newsletter.service';
import { RouteService } from '../service/route.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  newsletterDataForm!: UntypedFormGroup;
  isSubscribe$: Observable<boolean>;
  image: string = '/assets/img/newsletter/nl-image-page.png';
  user: User;
  showModal: boolean = false;
  typePopup: string = '';
  email: string = '';

  constructor(
    private _userService: UserService,
    private _newsletterService: NewsletterService,
    private _fb: UntypedFormBuilder,
    private _route: RouteService,
  ) {
    this.user = this._userService.user$.getValue();
    this.initForm(this.user.email, false);
    this._newsletterService.cbkNewsletter(this.user.email);
    this.isSubscribe$ = this._newsletterService.newsletterStatus$;
  }

  ngOnInit(): void {

  }

  subscribe() {
    if (this.newsletterDataForm.valid) {
      const email = this.newsletterDataForm.value.newsletterMail;
      this._newsletterService.subscribeNewsletter(email).pipe(
        take(1)
      ).subscribe((response) => {
        if (response) {
          const responseJson = JSON.parse(response);
          if (responseJson.type == 'success') {
            this.openPopup("subscribe");
            this._newsletterService.cbkNewsletter(email);
          }
        }
      });
    }
  }

  unsubscribe() {
    if (this.newsletterDataForm.valid) {
      const email = this.newsletterDataForm.value.newsletterMail;
      this._newsletterService.unsubscribeNewsletter().pipe(
        take(1)
      ).subscribe((response) => {
        this.openPopup("unsubscribe");
        this._newsletterService.cbkNewsletter(email);
      });
    }
  }

  initForm(email: string, check: boolean) {
    this.newsletterDataForm = this._fb.group({
      newsletterMail: [email, Validators.required],
      flexCheckDefault: ['', Validators.required]
    });
  }

  openPopup(type: string) {
    this.typePopup = type;
    this.changeShowModal();
  }

  changeShowModal() {
    this.showModal = !this.showModal;
  }
  closeModal() {
    this.showModal = false;
  }

  cameBack() {
    this._route.back();
  }
}
