<app-gestione-articolo
  [scorta]="data.scorta"
  *ngIf="!data.isRiprogrammazione &&
  !data.idraulico &&
  !data.fuorizona &&
  !data.isSistemazione &&
  !data.isGeometra &&
  !data.isDolceCasa &&
  !data.isCostiGiacenza &&
  !data.smaltim &&
  !data.catesterno &&
  !data.isAddebitoTrasporto">
</app-gestione-articolo>
<app-parte-ordine
  [rev]="data.rev"
  [isSistemazione]="data.isSistemazione"
  [catEsterno]="data.catesterno">
</app-parte-ordine>
<app-sistemazione
  [isSistemazione]="data.isSistemazione">
</app-sistemazione>
<app-costi-giacenza
  [isCostiGiacenza]="data.isCostiGiacenza">
</app-costi-giacenza>
<app-ritiro-merce
  [isRitiroMerce]="data.isRitiroMerce">
</app-ritiro-merce>
<app-idraulico
  [idraulico]="data.idraulico">
</app-idraulico>
<app-smaltimento
  [smaltim]="data.smaltim">
</app-smaltimento>
<app-riprogrammazione
  [isRiprogrammazione]="data.isRiprogrammazione">
</app-riprogrammazione>
<app-cambio
  [cambioart]="data.cambioart">
</app-cambio>
<app-cat-esterno
  [catesterno]="data.catesterno">
</app-cat-esterno>
<app-dolce-casa
  [dolceCasa]="data.isDolceCasa">
</app-dolce-casa>
<app-geometra
  [geometra]="data.isGeometra">
</app-geometra>
<app-fuori-zona
  [isFuoriZona]="data.fuorizona">
</app-fuori-zona>
<app-costi-viaggio
  [isTraghetto]="data.isTraghetto"
  [isAddebitoTrasporto]="data.isAddebitoTrasporto">
</app-costi-viaggio>
