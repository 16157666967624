<div id="personal_area" class="container">
  <div class="row gap-50 justify-content-between">
    <div class="col-md-6">
      <h1 class="text-center mb-md-1 mb-sm-3">Inserisci email da cercare</h1>
      <form [formGroup]="searchForm" class="mb-4">
        <div class="row mb-md-3 mb-sm-3">
          <div class="form-searchFormGroup w-100">
            <input class="form-control" id="email" type="text" formControlName="email" placeholder="Email" />
            <app-error-form [fieldControl]="searchForm.controls['email']" codeError="errors.email"></app-error-form>
          </div>
        </div>
        <button type="submit" class="btn btn-primary w-100 mb-0" [disabled]="searchForm.invalid" (click)="onSubmit()">
          {{ "buttons.search" | translate }}
        </button>
      </form>
    </div>
  </div>
</div>
<app-loader *ngIf="loader"></app-loader>
