import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DeviceService } from '../service/device.service';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent {
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() previousStepModal = new EventEmitter<number>();
  @Input() curStep?: number;
  @Input() modalClass?: string;

  disabledFirstStep:boolean = false;
  isMobile: boolean = false;

  constructor(
    public _detectorService: DeviceService
  ) {
    this.isMobile = this._detectorService._isMobile.getValue();
  }

  ngOnChanges(changes: SimpleChanges) {

    if(changes.curStep.currentValue) {
      this.disabledFirstStep = changes.curStep.currentValue == 1 || changes.curStep.currentValue == 0 ? true : false;
    }
  }

}
