<button class="btn btn-blue-reverse btnDetail" (click)="goToDetailOrder()" name="btnDetail"
  *ngIf="showButtonDetail">
  <span *ngIf="(rev === '0' || +rev);else isOrderdWithRevisione">
    {{ 'buttons.detailInvoice' | translate | uppercase }}
  </span>
  <ng-template #isOrderdWithRevisione>
    <span>
      {{ 'buttons.detail' | translate | uppercase }}
    </span>
  </ng-template>
</button>
<button class="btn btn-green" (click)="goToReservation()" name="btnEditAppointment" *ngIf="showButtonAppointmentEdit">
  {{ 'buttons.editAppointment' | translate | uppercase }}
</button>
<button class="btn btn-green" (click)="goToReservation()" name="btnCreateAppointment" *ngIf="showButtonAppointment">
  <!--&& !dropOff && isEstero -->
  {{ ((dapp === '' || dapp == null) ? 'buttons.createAppointment' : 'buttons.editAppointment') | translate | uppercase
  }}
</button>
<!-- <button class="btn btn-green" name="btnCompleted" *ngIf="showButtonCompleted">
  {{ 'buttons.completed' | translate | uppercase }}
</button> -->
<button class="btn btn-light-gray customize-btn-grey" name="btnDeleted" *ngIf="showButtonDeleted && !isMobile">
  {{ 'buttons.deleted' | translate | uppercase }}
</button>
<div *ngIf="isAfterOrder && (_apiService.isGuest$ | async) && isDetail;else isPreviousOrder">
  <button class="btn btn-gray" (click)="changeShowModal(true)">
    {{ 'buttons.invoice' | translate | uppercase }}
  </button>
</div>
<ng-template #isPreviousOrder>
  <button class="btn btn-primary" (click)="changeShowModal(); cbkGtmRequestInvoice();" name="btnInvoice"
    *ngIf="isDetail && invoice && invoice.length > 0">
    {{ 'buttons.invoice' | translate | uppercase }}
  </button>
</ng-template>
<button class="btn btn-blue-reverse btnDetail" (click)="changeShowModalBarcode()" *ngIf="showBarcodeButton">
  {{ 'buttons.barcode' | translate | uppercase }}
</button>
<app-custom-modal *ngIf="showModal" (closeModal)="closeModal()" modalClass="mobile-height">
  <ng-container>
    <div class="Modal-container invoice">
      <h3 class="mb-3 text-center text-uppercase">{{ "invoice.modal.title" | translate }}</h3>
      <div *ngIf="invoice && invoice.length > 0">
        <div class="row-invoice row mb-1" *ngFor="let e of invoice">
          <div class="justify-content-between d-flex flex-row border-bottom align-items-center mt-2 mb-2 pb-3">
            <span class="text-right">
              {{ "invoice.modal.prefix" | translate | titlecase }} {{e.num}} {{ "invoice.modal.del" | translate |
              titlecase }} {{ e.date}}
            </span>
            <a [href]="e.url" target="_blank" (click)="cbkGtmDownloadInvoice()"><i
                class="bi bi-download icon-download"></i></a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-custom-modal>
<app-custom-modal *ngIf="showModalMoreService" (closeModal)="closeModal(true)" modalClass="mobile-height">
  <ng-container>
    <div class="Modal-container" [ngClass]="{'mt-10': isMobile}">
      <h3 class="mb-3">{{ "table.modal.txtTitle" | translate }}</h3>
      <p class="mb-4">{{ "table.modal.txtDes" | translate }}</p>
      <div class="row">
        <div class="col-md-6">
          <button class="btn btn-blue-reverse mb-4 w-100" [ngClass]="{'btn-mobile': isMobile}" (click)=" goToSignIn()">
            {{ "buttons.access" | translate | uppercase}}
          </button>
        </div>
        <div class="col-md-6">
          <button class="btn btn-primary mb-4 w-100" [ngClass]="{'btn-mobile': isMobile}" (click)=" goToSignUp()">
            {{ "buttons.signUp" | translate | uppercase}}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</app-custom-modal>
<app-custom-modal *ngIf="showModalBarcode" (closeModal)="closeModal()" modalClass="barcode">
  <ng-container>
    <div class="Modal-container" [ngClass]="{'mt-10': isMobile}">
      <div class="row">
        <div class="col-md-6">
          <div style='text-align: center;'>
            <!-- insert your custom barcode setting your data in the GET parameter "data" -->
            <img alt='{{orderId}}'
              src='https://barcode.tec-it.com/barcode.ashx?data={{orderId}}&code=Code128&translate-esc=on' />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-custom-modal>
