import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { User } from 'src/entities/user';
import { RouteService } from '../service/route.service';
import { UserService } from '../service/user.service';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
})
export class PersonalDataComponent implements OnInit {
  show_button: Array<boolean> = [false, false, false];
  show_eye: Array<boolean> = [false, false, false];
  personalDataForm!: UntypedFormGroup;
  showPwd: boolean = false;
  user: User;
  showModal: boolean = false;

  constructor(
    private _fb: UntypedFormBuilder,
    private _userService: UserService,
    private _route: RouteService,
    private _utilityService: UtilityService
  ) {
    this.user = this._userService.user$.getValue();
    this.initForm();
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.personalDataForm.valid) {
      this._userService.edit(this.personalDataForm.value.firstName, this.personalDataForm.value.lastName).subscribe((response) => {
        if(response) {
          const user: User = this._userService.getUser();
          user.firstname = this.personalDataForm.value.firstName;
          user.lastname = this.personalDataForm.value.lastName;
          this._userService.setFullname$.next(`${user.firstname} ${user.lastname}`);
          console.log("Dati modificati con successo");
        }
        console.log("error")
      })
    }
  }

  onDelete() {
    this._userService.delete().subscribe((response) => {
      console.log("response");
      if(response) {
        this._utilityService.exitMenu();
      }
      console.log("error")
    })
  }

  initForm() {let formGroupObj = {}
    if (!this.user) {
      formGroupObj = {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
      };
    } else {
      formGroupObj = {
      firstName: [this.user.firstname, Validators.required],
      lastName: [this.user.lastname, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]],
    };
  }

    this.personalDataForm = this._fb.group({
      ...formGroupObj,
      /* piva: [''],
      pwd: [''],
      changePwd: [''],
      newPwd: [''],
      repeatNewPwd: [''], */
    });
  }

  showPassword(n: number) {
    this.show_button[n] = !this.show_button[n];
    this.show_eye[n] = !this.show_eye[n];
  }
  changeShowPwd(e: any) {
    this.showPwd = e.target.checked;
  }

  cameBack() {
    this._route.back();
  }
  changeShowModal() {
    this.showModal = !this.showModal;
  }
  closeModal() {
    this.showModal = false;
  }
}
