import { ACCEPTED_STATE } from 'src/config/constats';
import { ApiService } from '../service/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { GtmService } from '../service/gtm.service';
import { Invoice } from 'src/entities/invoice';
import { Order } from 'src/entities/order';
import { Router } from '@angular/router';
import { RouteService } from '../service/route.service';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-orders-actions',
  templateUrl: './orders-actions.component.html',
  styleUrls: ['./orders-actions.component.scss']
})
export class OrdersActionsComponent implements OnInit {
  @Input() data!: Order;
  @Input() isDetail!: boolean;
  @Input() invoice: Invoice[] | null = null;

  isAfterOrder: boolean = false
  isMobile: boolean = false;
  showBarcodeButton: boolean = false;
  showButtonAppointment: boolean = false;
  showButtonAppointmentEdit: boolean = false;
  // showButtonCompleted: boolean = false;
  showButtonDeleted: boolean = false;
  showButtonDetail: boolean = false;
  showModal: boolean = false;
  showModalBarcode: boolean = false;
  showModalMoreService: boolean = false;

  dapp: string | null = '';
  orderId: string = '';
  rev: string = '';

  constructor(
    private _gtmService: GtmService,
    private _routeService: RouteService,
    private _utilityService: UtilityService,
    private router: Router,
    public _apiService: ApiService,
    public _detectorService: DeviceService,
  ) {
    this.isMobile = this._detectorService._isMobile.getValue();
  }

  ngOnInit(): void {
    /** base data from input */
    const {
      annullato,
      catesterno,
      consegnaKey,
      dapp,
      dbu,
      perapp,
      prbu,
      rev,
      saldo,
      statoKey,
      dateutili,
      isCatanzaroDep,
      isRevisionWithAppointment,
    } = this.data;

    this.dapp = dapp;
    this.orderId = prbu + rev;
    this.rev = rev;

    /** from base data  */
    this.showBarcodeButton = consegnaKey == 'R' && saldo > 0 && this.isDetail

    this.isAfterOrder = this._utilityService.isOrderAfterGoLive(dbu);


    /** Pulsante fissa/modifica appuntamento*/
    this.showButtonAppointment = this._utilityService.getShowCreateAppointment(
      statoKey,
      perapp,
      annullato,
      catesterno,
      false,
      isCatanzaroDep,
      isRevisionWithAppointment
    );

    /** Pulsante modifica appuntamento */
    this.showButtonAppointmentEdit = this._utilityService.getShowEditAppointment(
      statoKey,
      perapp,
      annullato,
      catesterno,
      false,
      dateutili,
      isCatanzaroDep,
      isRevisionWithAppointment
    );

    // this.showButtonCompleted = !this.isDetail && statoKey == ACCEPTED_STATE[6];
    this.showButtonDeleted = statoKey == ACCEPTED_STATE[5] || annullato;
    this.showButtonDetail = !this.isDetail && statoKey != ACCEPTED_STATE[5] && !annullato;

  }

  goToDetailOrder () {
    this.router.navigate(['/orderDetail', this.orderId]);
  };

  goToReservation () {
    this.router.navigate(['/reservation', this.orderId]);
  }

  changeShowModal(isAfter: boolean = false) {
    if (isAfter) {
      this.showModalMoreService = !this.showModalMoreService
    } else {
      this.showModal = !this.showModal;
    }
  }

  changeShowModalBarcode() {
      this.showModalBarcode = !this.showModalBarcode;
  }

  closeModal(isAfter: boolean = false) {
    if (isAfter) {
      this.showModalMoreService = false
    } else {
      this.showModal = false;
      this.showModalBarcode = false;
    }
  }

  goToSignUp() {
    this._routeService.goTo('signup');
  }

  goToSignIn() {
    this._routeService.goTo('login');
  }

  cbkGtmDownloadInvoice() {
    this._gtmService.eventDownloadInvoice();
  }

  cbkGtmRequestInvoice() {
    this._gtmService.eventInvoice();
  }
}
