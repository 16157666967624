import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor(
    private _translate: TranslateService
  ) { }

  init() {
    this._translate.setDefaultLang('it');
    this._translate.use('it');
  }

  /**
   *
   * @returns string;
   */
  getImageByLang() {
    return (this._translate.currentLang === 'it') ? '/assets/img/icon-loading-it.png' : '/assets/img/icon-loading.png';
  }
}
