import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class TokenService {

  public token$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public type$: BehaviorSubject<string> = new BehaviorSubject<string>('default');

  constructor() {}

  setToken(token: string) {
    this.token$.next(token);
  }

  getToken() {
    return this.token$.getValue();
  }

  setTypeToken(type: string) {
    this.type$.next(type);
  }

  getTypeToken() {
    return this.type$.getValue();
  }
}

