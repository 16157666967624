import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuestService {

  guestCode$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {}

  init() {}

  getGuid(): string {
    return this.guestCode$.getValue();
  }

  setGuid(guid:string) {
    return this.guestCode$.next(guid);
  }

}
