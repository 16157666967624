import { AdminService } from "./admin.service";
import { Assistance } from "src/entities/assistance";
import { AssistanceParams } from "src/entities/AssistanceParams";
import { BehaviorSubject, map, Observable, of, shareReplay, switchMap } from "rxjs";
import { CodOperatoreService } from "./codOperatore.service";
import { HeadersService } from "./headers.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlService } from "./url.service";
import { UserService } from "./user.service";

interface AssistanceToSend {
  guid: string,
  idbc:  string,
  idbuodes: number,
  messaggio: string,
  cidra1: string,
  email: string | null
  cuseron?: number
};

@Injectable({
  providedIn: 'root',
})
export class AssistanceService {
  headers: HttpHeaders;
  private cbkAssistance$: BehaviorSubject<AssistanceParams>;
  assistance$: Observable<Assistance[]>;

  constructor(
    private _adminService: AdminService,
    private _codOperatoreService: CodOperatoreService,
    private _userService: UserService,
    private _urlService: UrlService,
    private _headersService: HeadersService,
    private http: HttpClient
  ) {
    const isAdmin = this._adminService.isAdmin$.getValue();
    this.headers = this._headersService.getHeaders(isAdmin);
    this.cbkAssistance$ = new BehaviorSubject<AssistanceParams>({ guid: '', idbc: '', idbuodes: 0, messaggio: '' });
    this.assistance$ = this.cbkAssistance$.pipe(
      switchMap((params) => {
        if (!params.guid && !params.idbc && !params.idbuodes && !params.messaggio) return of([] as Assistance[]); // gestire throw
        const url = this._urlService.getUrl('requestAssistance');
        const email = (this._userService.getEmail() && this._userService.getEmail() != '') ? this._userService.getEmail() : null;

        const objParams: AssistanceToSend = {
          guid: params.guid,
          idbc: params.idbc,
          idbuodes: params.idbuodes,
          messaggio: params.messaggio,
          cidra1: "03",
          email: email,
        };

        if (isAdmin) objParams.cuseron = parseInt(this._codOperatoreService.getCode());

        return this.http
          .post<[Assistance]>(url,
            objParams, {
            headers: this.headers,
            observe: `body`,
            responseType: 'json'
          })
      }),
      map((response: Assistance[]): Assistance[] => {
        return response;
      }),
      shareReplay(1),
    )
  }

  cbkAssistance(params: AssistanceParams) {
    this.cbkAssistance$.next(params);
    return this.assistance$;
  }
}

