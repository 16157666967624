<div class="Modal" (click)="closeModal.emit(false)">
  <div class="Modal-inner " (click)="$event.stopPropagation()">
    <div class="Modal-content">
      <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      <div class="mobileHeader">
        <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      </div>
      <div class="Modal-container serviceModal">
        <h4 *ngIf="title" class="mb-4 text-center">{{ title | uppercase}}</h4>
        <p *ngIf="desc" class="text-center"> {{ desc }}</p>
        <div *ngIf="navettaRowOne">
          <p> {{ navettaRowOne }}</p>
          <ul>
            <li>{{ navettaRowTwo }}</li>
            <li>{{ navettaRowThree }}</li>
            <li>{{ navettaRowFour }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
