import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  _isMobile = new BehaviorSubject<boolean>(false);

  constructor(
    private _deviceDetector: DeviceDetectorService
  ) {}

  init() {
    this._isMobile.next(this._deviceDetector.isMobile());
  }
}
