<app-custom-modal *ngIf="showModal" (closeModal)="closeModal()" modalClass="mobile-height">
    <ng-container>
      <div class="Modal-container">
        <div class="icon-modal">
          <i class="bi bi-exclamation-triangle me-3"></i>
        </div>
        <h3 class="mb-3 text-center">{{ "guestBox.wrongGuid" | translate }}</h3>
        <p class="mb-3">{{ "guestBox.descWrongGuid" | translate }}</p>
      </div>
    </ng-container>
</app-custom-modal>
