import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { ServiceModalComponent } from "../service-modal/service-modal.component";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ServiceProductModalService {
  private rootViewContainer!: ViewContainerRef;

  constructor(
    private factoryResolver: ComponentFactoryResolver,
    private _translate: TranslateService
  ) {
    this.factoryResolver = factoryResolver;
  }

  setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  addDynamicComponent(type: string) {
    let title;
    let desc;

    switch (type) {
      case 'ordinazioneArticolo':
        title = this._translate.instant('services.txtOrdinazione');
        desc = this._translate.instant('services.descOrdinazione');
        break;
      case 'scortaArticolo':
        title = this._translate.instant('services.txtScorta');
        desc = this._translate.instant('services.descScorta');
        break;
      case 'cambio':
        title = this._translate.instant('services.txtCambio');
        desc = this._translate.instant('services.descCambio');
        break;
      case 'parte':
        title = this._translate.instant('services.txtParte');
        desc = this._translate.instant('services.descParte');
        break;
      case 'revisione':
        title = this._translate.instant('services.txtAssistenza');
        desc = this._translate.instant('services.descAssistenza');
        break;
      case 'sistemazione':
        title = this._translate.instant('services.txtVerificaMontaggio');
        desc = this._translate.instant('services.descVerificaMontaggio');
        break;
      case 'giacenza':
          title = this._translate.instant('services.txtCostiGiacenza');
          desc = this._translate.instant('services.descCostiGiacenza');
        break;
      case 'ritiro':
        title = this._translate.instant('services.txtRitiroMerce');
        desc = this._translate.instant('services.descRitiroMerce');
        break;
      case 'riprogrammazione':
        title = this._translate.instant('services.txtRiprogrammazione');
        desc = this._translate.instant('services.descRiprogrammazione');
        break;
      case 'cambioRitiro':
        title = this._translate.instant('services.txtCambio');
        desc = this._translate.instant('services.txtCambioRitiro');
        break;
      case 'catEsterno':
        title = this._translate.instant('services.txtAssistenzaProduttore');
        desc = this._translate.instant('services.descAssistenzaProduttore');
        break;
      case 'geometra':
        title = this._translate.instant('services.txtRilievoMisure');
        desc = this._translate.instant('services.descRilievoMisure');
        break;
      case 'fuorizona':
          title = this._translate.instant('services.txtFuoriZona');
          desc = this._translate.instant('services.descFuoriZona');
          break;
      case 'traghetto':
          title = this._translate.instant('services.txtTraghetto');
          desc = this._translate.instant('services.descTraghetto');
          break;
      case 'addebitoTrasporto':
        title = this._translate.instant('services.txtAddebitoTrasporto');
        desc = this._translate.instant('services.descAddebitoTrasporto');
        break;
      default:
        break;
    }

    const factory = this.factoryResolver.resolveComponentFactory(ServiceModalComponent);
    const component = factory.create(this.rootViewContainer.parentInjector);
    component.instance.type = type;
    component.instance.title = title;
    component.instance.desc = desc;
    // Subscribe to the closeModal event and destroy the component
    component.instance.closeModal.subscribe(() => this.removeDynamicComponent(component));

    this.rootViewContainer.insert(component.hostView);
  }

  removeDynamicComponent(component: ComponentRef<any>) {
    component.destroy();
  }
}
