<div id="list_address" class="container">
  <div class="row mb-5">
    <div class="col-md-6 text-md-start text-sm-center text-sm-left-c">
      <h1>
        <i class="fa fa-envelope-o" aria-hidden="true"></i>
        <span class="border-bottom border-red border-3 pb-2 d-inline-block">{{ "address.txtSection" | translate
          }}</span>
      </h1>
    </div>
    <div class="col-md-6 text-md-end text-sm-start text-sm-right-c position-sm-absolute d-sm-block">
      <a (click)="cameBack()" class="btn-link">
        <i class="fa fa-arrow-left d-md-none text-red fz-25" aria-hidden="true"></i>
        <span class="d-md-block d-sm-none">
          < {{ "common.cameBack" | translate }}</span>
        </a>
    </div>
  </div>

  <div *ngFor="let address of addresses | async" class="single_address rounded shadow p-md-5 mb-5">
    <div class="row justify-content-between">
      <div class="col-md-8">
        <div class="border-bottom pb-md-2 pb-sm-3 mb-3">
          <p class="par_2 mb-md-2 mb-sm-1">{{ address.nom }} {{ address.cog }}</p>
          <p class="par_2 mb-md-2 mb-sm-1">{{ address.ind }}, {{ address.civico }}</p>
          <p class="par_2 mb-md-2 mb-sm-1"> {{ address.comune }}, {{ address.cap }}</p>
        </div>
      </div>
      <div class="col-md-4 text-end">
        <button (click)="goToDetailAddress(address.id)" name="btnDetail" class="btn btn-primary">
          {{ 'buttons.viewAddress' | translate | uppercase}}
        </button>
        <!-- <button (click)="deleteAddress(address.id)" name="btnDelete" class="btn btn-gray">
          {{ 'buttons.delete' | translate | uppercase}}
        </button> -->
      </div>
    </div>
  </div>
  <!-- <div id="addNewAddress" class="d-md-inline-block float-md-end">
    <button class="border-0 bg-transparent text-green d-flex align-items-center m-auto" (click)="addAddress()">
      <i class="fa fa-plus me-2" aria-hidden="true"></i>
      <h3 class="fw-semibold">{{ 'address.addNewAddress' | translate }}</h3>
    </button>
  </div> -->
</div>
