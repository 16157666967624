import { ApiService } from '../service/api.service';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { DeviceService } from '../service/device.service';
import { FacebookLoginProvider,GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { GtmService } from '../service/gtm.service';
import { map, switchMap } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Router } from '@angular/router';
import { TokenService } from '../service/token.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, } from '@angular/forms';
import { User } from "src/entities/user";
import { UserService } from '../service/user.service';
import { LoginService } from '../service/login.service';

@Component({
  selector: 'app-login-box',
  templateUrl: './login-box.component.html',
  styleUrls: ['./login-box.component.scss'],
})
export class LoginBoxComponent {
  @Output() changeLoginBox = new EventEmitter<boolean>();
  @Input() redirect: boolean = false;
  @Input() redirectUrl: string = '';

  GoogleLoginProvider = GoogleLoginProvider;

  googleBtn?: ElementRef;
  isMobile: boolean = false;
  loggedIn: boolean = false;
  loginDataForm!: UntypedFormGroup;
  showModal: boolean = false;
  show_button: boolean = false;
  show_eye: boolean = false;
  urlServizi: string = '';
  user: SocialUser = new SocialUser();

  host: string = "";
  loader: boolean = false;

  titleModal: string = '';
  descModal: string = '';

  constructor(
    private _apiService: ApiService,
    private _fb: UntypedFormBuilder,
    private _gtmService: GtmService,
    private _loginService: LoginService,
    private _tokenService: TokenService,
    private _translate: TranslateService,
    private _userService: UserService,
    private readonly authService: SocialAuthService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: Router,
    public _detectorService: DeviceService,
  ) {
    this._gtmService.eventNotLogged(window.location.pathname);
    this.initForm();

    switch (window.location.host) {
      case 'account-sg.mondoconv.it':
        this.host = 'https://staging2.mondoconv.it';
        break;

      case 'account.mondoconv.it':
        this.host = 'https://www.mondoconv.it';
        break;

      default:
        this.host = 'https://www.mondoconv.it';
        break;
    }

    _translate.get('login.urlServizioClienti').subscribe((res: string) => {
      this.urlServizi = res;
    });

    this.loginDataForm.controls['email'].valueChanges
      .subscribe(x => {
        if (x && x.includes(' ')) {
          this.loginDataForm.controls['email'].setValue(x.trim())
        }
      })
    this.isMobile = this._detectorService._isMobile.getValue();
  }

  ngOnInit() {
    this.authService.authState.subscribe((response) => {
      if (response) {
        const user: User = new User;
        if (response.provider === 'FACEBOOK') {
          this._gtmService.eventLoginFb(this._userService.getUser(), response.id);
          user.authToken = response.authToken;
          this._tokenService.setTypeToken('facebook');
          this._tokenService.setToken(user.authToken);
        }

        if (response.provider === 'GOOGLE') {
          this._gtmService.eventLoginG(this._userService.getUser(), response.id);
          user.authToken = response.idToken;
          this._tokenService.setTypeToken('google');
          this._tokenService.setToken(user.authToken);
        }

        const { email, lastName, firstName } = response;
        user.email = email;
        user.firstname = firstName;
        user.lastname = lastName;
        this._userService.setUser(user);
        this._apiService.isLogged$.next(true);
        this._userService.setFullname$.next(`${firstName} ${lastName}`);
        this.route.navigate(['/dashboard']);
      }
    });
  }

  goToSignUp() {
    if (this.redirect) {
      this.route.navigate(['/signup'], { queryParams: { mode: 'redirect', redirectUrl: this.redirectUrl } });
    } else this.route.navigate(['/signup']);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

  refreshGoogleToken(): void {
    this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

  onSubmit() {
    if (this.loginDataForm.valid) {
      let res:number = 0;
      this._loginService.login(this.loginDataForm.value.email.trim(), this.loginDataForm.value.password).pipe(
        switchMap((response: number) => {
          res = response;
          return this.recaptchaV3Service.execute('login');
        }),
        map((token: string) => {
          if (!token) this.route.navigate(['/login']);
          return res;
        }),
        map((response: number) => {
        switch (response) {
          case 200:
            this._gtmService.eventLogin(this._userService.getUser());
            if (this.redirect) {
              this.loader = true;
              setTimeout(() => {
                const pathnameToRedirect = (!this.redirectUrl) ? "/" : (this.redirectUrl === '/menu') ? "/" : this.redirectUrl;
                window.location.replace(this.host + pathnameToRedirect);
                this.loader = false;
              }, 3000);
            }
            else {
              this.loginDataForm.reset();
              this.route.navigate(['/dashboard']);
            }
            break;
          default:
              /*
                401: "Email o password errate"
                402: "Email o password vuote"
                403: "Account non registrato"
                404: "Opt-in non confermato"
                405: "Account bloccato"
              */
            this.titleModal = this._translate.instant(`login.title${response}`);
            this.descModal = this._translate.instant(`login.desc${response}`);
            this.changeShowModal();
            break;
        }
      }),
      ).subscribe();
    }
  }

  initForm() {
    this.loginDataForm = this._fb.group({
      email: ['', [Validators.pattern("^[a-zA-Z0-9._|\\\\%#~`=?&/$^*!}{+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z ]{2,4}$"), Validators.required]],
      password: ['', Validators.required],
    });
  }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

  setLoginBox(value: boolean) {
    this.changeLoginBox.emit(value);
  }

  changeShowModal() {
    this.showModal = !this.showModal;
  }
  closeModal() {
    this.showModal = false;
  }
}
