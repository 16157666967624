import { ApiService } from "../service/api.service";
import { Component } from '@angular/core';
import { CustomCookieService } from "../service/customCookie.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-banner-bottom',
  templateUrl: './banner-bottom.component.html',
  styleUrls: ['./banner-bottom.component.scss'],
})
export class BannerBottomComponent {
  showBanner: boolean = false;
  constructor(
    private _apiService: ApiService,
    private _customCookieService: CustomCookieService,
    private router: Router
  ) {
    this.showBanner = this._apiService.isLogged$.getValue();
  }

  onActionClick(evt: MouseEvent) {
    const target = evt.target as Element;
    if (target && target.classList.contains('bnr-action')) {
      evt.preventDefault();
      this._apiService.isLogged$.next(false);
      this._customCookieService.deleteCookie(['auth_token', 'auth_token_sso']);
      this.router.navigate(['/login'], {queryParams: {mode: 'guest'}});
    }
  }
}
