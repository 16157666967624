import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceService } from '../service/device.service';

@Component({
  selector: 'app-shipping-modal',
  templateUrl: './shipping-modal.component.html',
  styleUrls: ['./shipping-modal.component.scss'],
})
export class ShippingModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter<any>();
  isMobile: boolean = false;

  constructor(
    private _deviceService: DeviceService
  )  {
    this.isMobile = this._deviceService._isMobile.getValue();
  }
  ngOnInit() {}

  close(event: any) {
    this.closeModal.emit(event);
  }
}
