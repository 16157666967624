<div class="Modal" (click)="closeModal.emit(false)">
  <div class="Modal-inner" (click)="$event.stopPropagation()">
    <div class="Modal-content">
      <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      <div class="mobileHeader">
        <h4 class="mb-4">
          {{ "services.titleShipping" | translate | uppercase }}
        </h4>
        <i class="bi bi-x" (click)="closeModal.emit(false)"></i>
      </div>
      <div class="Modal-container">
        <div id="shipping-modal" class="row pb-sm-0 mb-4">
          <div
            class="row pb-md-0 mb-md-0 text-center"
            [ngClass]="isMobile ? 'mb-sm-1 pb-sm-1' : 'pb-sm-4 mb-sm-5'"
          >
            <div class="col-md-12">
              <h4 class="mb-4">
                {{ "services.titleShipping" | translate | uppercase }}
              </h4>
            </div>
          </div>
          <div class="row pb-md-3 pb-sm-0 mb-3">
            <div class="col-md-6" [ngClass]="{ 'mb-sm-4': isMobile }">
              <p class="mb-3 fw-bold">{{ "services.txtTrasportoMontaggio" | translate }}</p>
              <p [innerHTML]="'services.descTrasportoMontaggio' | translate"></p>
            </div>
            <div class="col-md-6">
              <p class="mb-3 fw-bold">{{ "services.txtRitiroCliente" | translate }}</p>
              <p [innerHTML]="'services.descRitiroCliente' | translate"></p>
            </div>
          </div>
          <div class="row pb-md-5 pb-sm-0 mb-5">
            <div class="col-md-6" [ngClass]="{ 'mb-sm-4': isMobile }">
              <p class="mb-3 fw-bold">{{ "services.txtSottoCasaTua" | translate }}</p>
              <p [innerHTML]="'services.descSottoCasaTua' | translate"></p>
            </div>
            <div class="col-md-6">
              <p class="mb-3 fw-bold">{{ "services.txtNavetta" | translate }}</p>
              <p>{{ "services.descNavetta" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
