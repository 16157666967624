export const headerLinksData = {
  it: {
    puntiVendita: "punti-vendita",
    faq: "faq",
    account: "account/login",
    catalogo: "catalogo",
    faiDaTe: "https://servizi.mondoconv.it/ordini",
  },
  es: {
    puntiVendita: "punti-vendita",
    faq: "faq",
    account: "account/login",
    catalogo: "catalogo",
    faiDaTe: "https://servizi.mondoconv.it/ordini",
  }
};
