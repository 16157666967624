<div id="login_page" class="d-flex align-items-center py-md-5 py-sm-4">
  <div class="container" style="height: 100vh;">
    <div class="row logo">
      <div class="col-md-5">
        <a aria-label="Go to homepage" href="{{ mondoConv | translate}}" class="Image-Logo" target="_blank">
          <img src="{{ ((isMobile || isWidth) ? logoMobileUrl : logoUrl) | translate }}" alt="Mondo Convenienza Logo" class="mb-md-5 mb-sm-4 mx-auto d-block mondo-logo-image" />
          <img src="{{ logoMobileUrl | translate }}" alt="Mondo Convenienza Logo" class="mb-md-5 mb-sm-4 mx-auto d-block negative-image" />
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 box">
          <app-login-box-admin></app-login-box-admin>
      </div>
      <div class="col-md-6 offset-1 d-md-flex d-sm-none justify-content-between flex-column">
        <img [src]="image" alt="img newsletter" class="w-100">
        <div id="box_text" class="align-items-end pt-md-5 text-end">
          <h2 class="fz-60 text-white">{{ "common.slogan1" | translate }}</h2>
          <h2 class="fz-60 text-white slogan2">{{ "common.slogan2" | translate }}</h2>
          <h2 class="fz-60 text-white mobile">{{ "common.slogan1" | translate }} {{ "common.slogan2" | translate }}</h2>
          <p class="par_2 text-white">© Mondo Convenienza -
            <a href="{{ privacyPolicy }}" class="text-white btn-link fw-regular">Privacy</a> e
            <a href="{{ cookiePolicy }}" class="text-white btn-link fw-regular">Cookie</a> -
            <a [href]="urlServizi" class="text-white btn-link fw-regular" target="_blank">{{ "login.desNewClient4" | translate }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div id="fascia_blu" class="col-md-5 col-sm-12 bg-primary h-100 position-fixed"></div>
</div>
