import { ApiService } from "./api.service";
import { BehaviorSubject, Observable, map, of } from "rxjs";
import { HeadersService } from "./headers.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TokenService } from "./token.service";
import { UrlService } from "./url.service";
import { UserService } from "./user.service";
import { catchError } from "rxjs/operators";
import { CodOperatoreService } from "./codOperatore.service";

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  /** Observable */
  adminObs$!: Observable<any>;

  /** BehaviorSubject */
  public cod$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  adminLogin$: BehaviorSubject<{ email: string, password: string }> = new BehaviorSubject<{ email: string, password: string }>({
    email: '',
    password: ''
  });

  isAdmin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** Variables */
  headers: HttpHeaders;

  constructor(
    private _apiService: ApiService,
    private _codOperatore: CodOperatoreService,
    private _headersService: HeadersService,
    private _router: Router,
    private _tokenService: TokenService,
    private _urlService: UrlService,
    private _userService: UserService,
    private http: HttpClient
  ) {
    this.headers = this._headersService.getHeaders();
    this.isAdmin$.asObservable();
    if (this._router.url === '/admin') this.adminObs$ = this.login("", "");
  }

  login(email: string, password: string) {
    if (!email || !password) return of(400);
    const url = this._urlService.getUrl('loginAdmin');
    return this.http
      .post(url, {
        username: email.trim(),
        password: password
      }, {
        headers: this.headers,
        observe: `body`,
        responseType: 'json'
      })
      .pipe(
        map((response: any) => {
          if (response && response[2] == 200) {
            this._userService.setFullname$.next(`${response[0].firstname} ${response[0].lastname}`);
            this._tokenService.setToken(response[0].auth_token); // capire se portarlo
            this._codOperatore.setCode(response[0].id);
            this._apiService.isLogged$.next(true); //
            this.isAdmin$.next(true);
            return 200;
          }
          return 400;
        }),
        catchError((e:any) => {
          return of(e.error.code);
        })
      );
  }

  refreshAdminLogin(params: { email: string, password: string }) {
    this.adminLogin$.next(params);
  }
}
