import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-form',
  templateUrl: './error-form.component.html',
  styleUrls: ['./error-form.component.scss'],
})
export class ErrorFormComponent implements OnInit {
  @Input() fieldControl!: any;
  @Input() codeError!: string;

  checkValidations: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.fieldControl.errors) {
      switch (this.codeError) {
        case 'errors.email':
          this.checkValidations =
            this.fieldControl.errors!.required ||
            this.fieldControl.errors!.email;
          break;
        case 'errors.password':
          this.checkValidations =
            this.fieldControl.errors!.required ||
            this.fieldControl.errors!.password;
          break;
        case 'errors.passwordReq':
          this.checkValidations =
            this.fieldControl.errors!.required
            break;
        default:
          this.checkValidations = this.fieldControl.errors!.required;
          break;
      }
    }
  }
}
