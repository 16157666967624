<div id="sidebar_menu" class="rounded shadow p-5 bg-white">
    <ul class="p-0 list-unstyled">
      <li *ngIf="isAdmin | async as isAdmin">
        <a [routerLink]="[search.link]" routerLinkActive="router-link-active" [routerLinkActiveOptions]="{exact: true}"
        (click)="closeMobileMenu()">
        <p class="py-3 border-bottom d-flex flex-row justify-content-start align-items-start">
          <img [src]="isMobile ? search.iconM : search.icon" alt="Logo elemento" class="me-2" width="20px">
          {{ search.txt | translate | uppercase }}
        </p>
      </a>
      </li>
      <div *ngIf="(isAdmin | async); else notAdmin">
        <li *ngFor="let e of menu" >
          <ng-template [ngIf]="e.txt !== 'menu.helper' && e.showAdmin" >
            <a [routerLink]="[e.link]" routerLinkActive="router-link-active" [routerLinkActiveOptions]="{exact: true}"
            (click)="closeMobileMenu()">
            <p class="py-3 border-bottom d-flex flex-row justify-content-start align-items-start">
              <img [src]="isMobile ? e.iconM : e.icon" alt="Logo elemento" class="me-2" [ngClass]="e.classImg" width="20px">
              {{ e.txt | translate | uppercase }}
            </p>
          </a>
          </ng-template>
        </li>
      </div>
      <ng-template #notAdmin>
        <li *ngFor="let e of menu" >
          <ng-template [ngIf]="e.txt !== 'menu.helper'" [ngIfElse]="helper">
            <a [routerLink]="[e.link]" routerLinkActive="router-link-active" [routerLinkActiveOptions]="{exact: true}"
            (click)="closeMobileMenu()">
            <p class="py-3 border-bottom d-flex flex-row justify-content-start align-items-start">
              <img [src]="isMobile ? e.iconM : e.icon" alt="Logo elemento" class="me-2" [ngClass]="e.classImg" width="20px">
              {{ e.txt | translate | uppercase }}
            </p>
          </a>
          </ng-template>
          <ng-template #helper>
            <a href="{{ e.link }}" (click)="closeMobileMenu()" target="_blank">
            <p class="py-3 border-bottom d-flex flex-row justify-content-start align-items-start">
              <img [src]="isMobile ? e.iconM : e.icon" alt="Logo elemento" class="me-2" [ngClass]="e.classImg" width="20px">
              {{ e.txt | translate | uppercase }}
            </p>
          </a>
          </ng-template>
        </li>
      </ng-template>
      <li>
        <a role="button" (click)="exitMenu()">
          <p class="py-3 border-bottom d-flex flex-row justify-content-start align-items-start">
            <img [src]="isMobile ? exit.iconM : exit.icon" alt="Logo elemento" class="me-2" width="20px">
            {{ exit.txt | translate | uppercase }}
          </p>
        </a>
      </li>
    </ul>
</div>
