import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('Detail')
export class Detail {
  @JsonProperty('assflag', Boolean)
  public assflag: boolean = false;
  @JsonProperty('canale', String)
  public canale: string = '';
  @JsonProperty('ccod', Boolean)
  public ccod: boolean = false;
  @JsonProperty('consegna', String)
  public consegna: string = '';
  @JsonProperty('dcc', String)
  public dcc: string | null = '';
  @JsonProperty('des', String)
  public des: string = '';
  @JsonProperty('descrweb', String)
  public descrweb: string = '';
  @JsonProperty('idbuodes', Number)
  public idbuodes: number = 0;
  @JsonProperty('importo', Number)
  public importo: number = 0;
  @JsonProperty('quantita', Number)
  public quantita: number = 0;
  @JsonProperty('sid', String)
  public sid: string = '';
  @JsonProperty('tipologia', String)
  public tipologia: string = '';
  @JsonProperty('titolo', String)
  public titolo: string = '';
  @JsonProperty('trasporto', Boolean)
  public trasporto: boolean = false;
  @JsonProperty('hasImage', Boolean)
  public hasImage: boolean = false;
  @JsonProperty('imageUrl', String)
  public imageUrl: string = '';
  @JsonProperty('elementi', [Detail])
  public elementi?: Detail[] = [];
}
