import { Component } from '@angular/core';
import { LocalizationService } from '../service/localization.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  img: string = '';

  constructor(public _localizationService: LocalizationService) {
    this.img = this._localizationService.getImageByLang();
  }
}
