export const sectionsStructure = [
  {
    txt: 'menu.area',
    link: '/dashboard',
    des: '',
    img: '',
    icon: 'assets/img/iconeMenu/dashboard.png',
    iconM: 'assets/img/iconeMenu/dashboard-m.svg',
    allView: true,
    txtGoTo: '',
    showAdmin: false,
  },
  {
    txt: 'menu.orders',
    link: '/orders',
    des: 'dashboard.card.orders.des',
    img: 'assets/img/areaPersonale/ordini-icon.png',
    icon: 'assets/img/iconeMenu/orders.png',
    iconM: 'assets/img/iconeMenu/orders-m.svg',
    allView: false,
    txtGoTo: 'dashboard.card.orders.goTo',
    showAdmin: true,
  },
  {
    txt: 'menu.vouchers',
    link: '/vouchers',
    des: 'dashboard.card.vouchers.des',
    img: 'assets/img/areaPersonale/buonispesa-illustration.svg',
    icon: 'assets/img/iconeMenu/buono-big.png',
    iconM: 'assets/img/iconeMenu/buono-big-m.svg',
    allView: false,
    txtGoTo: 'dashboard.card.vouchers.goTo',
    showAdmin: true,
    classImg: "vouchers-image"
  },
  {
    txt: 'menu.personalData',
    link: '/personalData',
    des: '',
    img: 'assets/img/areaPersonale/personal_data.svg',
    icon: 'assets/img/iconeMenu/personal_data.png',
    iconM: 'assets/img/iconeMenu/personal_data-m.svg',
    allView: false,
    txtGoTo: 'dashboard.card.personalData.goTo',
    showAdmin: false,
  },
  {
    txt: 'menu.newsletter',
    link: '/newsletter',
    des: 'dashboard.card.newsletter.des',
    img: 'assets/img/areaPersonale/newsletter.svg',
    icon: 'assets/img/iconeMenu/newsletter.png',
    iconM: 'assets/img/iconeMenu/newsletter-m.svg',
    allView: false,
    txtGoTo: 'dashboard.card.newsletter.goTo',
    showAdmin: true,
  },
  {
    txt: 'menu.address',
    link: '/address',
    des: 'dashboard.card.address.des',
    img: 'assets/img/areaPersonale/address.svg',
    icon: 'assets/img/iconeMenu/address.png',
    iconM: 'assets/img/iconeMenu/address-m.svg',
    allView: false,
    txtGoTo: 'dashboard.card.address.goTo',
    showAdmin: true,
  },
  {
    txt: 'menu.helper',
    link: 'https://www.mondoconv.it/servizio-clienti',
    des: 'dashboard.card.helper.des',
    img: 'assets/img/areaPersonale/help.svg',
    icon: 'assets/img/iconeMenu/help.png',
    iconM: 'assets/img/iconeMenu/help-m.svg',
    allView: false,
    txtGoTo: 'dashboard.card.helper.goTo',
    showAdmin: false,
  }
];
export const exitSection = {
  txt: 'menu.exit',
  link: '/logout',
  des: '',
  img: '',
  icon: 'assets/img/iconeMenu/logout.png',
  iconM: 'assets/img/iconeMenu/logout-m.svg',
  allView: true,
  txtGoTo: '',
  showAdmin: true,
};

export const searchSection = {
  txt: 'buttons.search',
  link: '/search',
  des: '',
  img: '',
  icon: 'assets/img/iconeMenu/logout.png',
  iconM: 'assets/img/iconeMenu/logout-m.svg',
  allView: true,
  txtGoTo: '',
  showAdmin: true,
};
