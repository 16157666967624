<p
  *ngIf="scorta"
  (click)="openModal($event, 'scortaArticolo')"
  class="mb-md-0 labelServices">
    {{ "services.labelScorta" | translate }}
</p>
<p
  *ngIf="!scorta"
  (click)="openModal($event, 'ordinazioneArticolo')"
  class="mb-md-0 labelServices">
    {{ "services.labelOrdinazione" | translate }}
</p>
