import { BehaviorSubject, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Invoice, InvoiceObj } from 'src/entities/invoice';
import { InvoiceParams } from "src/entities/InvoiceParams";
import { isEmpty } from "lodash";
import { UrlService } from './url.service';
import { HeadersService } from './headers.service';

@Injectable({
  providedIn: 'root'
})

export class InvoiceService {
  private cbkInvoice$: BehaviorSubject<InvoiceParams>;
  invoices$: Observable<Invoice[]>;

  constructor(
    private _headersService: HeadersService,
    private _urlService: UrlService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    this.cbkInvoice$ = new BehaviorSubject<InvoiceParams>({ guid: '', prbu: '' });
    this.invoices$ = this.cbkInvoice$.pipe(
      switchMap((params) => {
        if (isEmpty(params.guid && params.prbu)) return of([] as InvoiceObj[]);
        const url = `${this._urlService.getUrl('invoice')}${params.guid}/${params.prbu}`

        return this.http
          .get<[InvoiceObj]>(url, {
            headers: this._headersService.getHeaders(),
            observe: `body`,
            responseType: 'json'
          })
      }),
      map((response: InvoiceObj[]): Invoice[] => {
        return response.map((e: InvoiceObj) => {
          const blob = new Blob([this.base64ToArrayBuffer(e.content)], { type: "application/pdf" });
          const splitName = (e.filename).split('_');
          const splitNum = (splitName[1]).split('.');
          return {
            url: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob)),
            date: splitName[0],
            num: splitNum[0]
          } as Invoice;
        })
      }),
      shareReplay(1),
    )
  }

  /**
  * Fn for refresh invoice api
  * @return {Observable} refresh invoice api.
  * @public
  */
  cbkInvoice(params: InvoiceParams) {
    this.cbkInvoice$.next(params);
    return this.invoices$;
  }

  /**
* convert base64toArrayBuffer
* @param base64
* @return Buffer
* @private
*/
  private base64ToArrayBuffer(base64: any) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

}


/*
  */
