import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Address } from "src/entities/address";

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  /** Variables */
  addresses: Address[] = [];

  /** Observable */
  addressesObs$: Observable<Address[]>;

  /** BehaviorSubject */
  addresses$: BehaviorSubject<Address[]> = new BehaviorSubject<Address[]>([]);
  addressIsEmpty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(
  ) {
    this.addressesObs$ = this.addresses$.asObservable();
  }
}

