<div id="box_form" class="shadow bg-white">
  <h1 class="text-center mb-md-1 mb-sm-3">{{ "buttons.signUp" | translate }}</h1>
  <p class="text-center mb-md-2 mb-2" style="font-size: 14px;">{{ "login.loginSubTitle" | translate }}</p>
  <form [formGroup]="createAccountForm" class="mb-4">
    <div class="row mb-md-4 mb-sm-3">
      <div class="form-createAccountForm w-100">
        <input class="form-control" id="email" type="text" formControlName="email" placeholder="Email" />
        <app-error-form [fieldControl]="createAccountForm.controls['email']" codeError="errors.email"></app-error-form>
      </div>
    </div>
    <div class="row mb-md-4 mb-sm-3">
      <div class="form-createAccountForm w-100">
        <input class="form-control" id="lastname" type="text" formControlName="lastname"
          [placeholder]="'commons.txtLastname' | translate" />
        <app-error-form [fieldControl]="createAccountForm.controls['lastname']" codeError="errors.lastname">
        </app-error-form>
      </div>
    </div>
    <div class="row mb-md-4 mb-sm-3">
      <div class="form-createAccountForm w-100">
        <input class="form-control" id="firstname" type="text" formControlName="firstname"
          [placeholder]="'commons.txtFirstname' | translate" />
        <app-error-form [fieldControl]="createAccountForm.controls['firstname']" codeError="errors.firstname">
        </app-error-form>
      </div>
    </div>
    <div class="mc-password-field input-group mx-0 mb-md-4 mb-sm-3">
      <input class="form-control" id="password" [type]="show_button[0] ? 'text' : 'password'" formControlName="password"
        placeholder="Password" />
      <i class="input-group-text" [class]="show_eye[0] ? 'bi bi-eye' : 'bi bi-eye-slash'" (click)="showPassword(0)"></i>
      <app-error-form [fieldControl]="createAccountForm.controls['password']" codeError="errors.password">
      </app-error-form>
    </div>
    <div class="mc-password-field input-group mx-0 mb-md-4 mb-sm-3">
      <input class="form-control" id="passwordRepeat" [type]="show_button[1] ? 'text' : 'password'"
        formControlName="passwordRepeat" [placeholder]="'createAccount.repeatPassword' | translate" />
      <i class="input-group-text" [class]="show_eye[1] ? 'bi bi-eye' : 'bi bi-eye-slash'" (click)="showPassword(1)"></i>
    </div>
    <div class="d-flex mb-md-2">
      <input class="mc-checkout-checkbox-field" id="privacy" type="checkbox" formControlName="privacy" />
      <label for="privacy" class="mc-checkout-checkbox-label-field">
        <span>
          {{ "createAccount.privacy1" | translate }}
          <a target="_blank" rel="noreferrer" [href]="'createAccount.privacyLink' | translate">
            {{"createAccount.privacy2" | translate}}
          </a> &nbsp;
          {{ "createAccount.privacy3" | translate }}
        </span>
      </label>
    </div>
    <div class="d-flex mb-md-2">
      <input class="mc-checkout-checkbox-field" id="marketing" type="checkbox" formControlName="marketing" />
      <label for="marketing" class="mc-checkout-checkbox-label-field">
        <span>
          {{ "createAccount.marketing1" | translate }}
          <a target="_blank" rel="noreferrer" href="{{ privacyPolicy }}">
            {{"createAccount.marketing2" | translate}}
          </a> &nbsp;
          {{ "createAccount.marketing3" | translate }}
        </span>
      </label>
    </div>
  </form>
  <button type="submit" (click)="onSubmit()" class="btn btn-primary w-100 mb-4">{{ "buttons.signUp2" | translate
    }}</button>
  <p class="par_2 text-center des-client">
    {{ "createAccount.desClient1" | translate }}&nbsp;
    <span (click)="goToSignIn()">{{ "buttons.access" | translate }}</span>
  </p>
</div>
<app-custom-modal *ngIf="showModal" (closeModal)="closeModal()" modalClass="mobile-height">
  <ng-container>
    <div class="Modal-container signup">
      <h3 *ngIf="txtEmail || txtPassword" class="mb-3 text-center">{{ "createAccount.modal.title" | translate }}</h3>
      <img *ngIf="txtSuccess" class="d-flex justify-content-center" style="margin: 0 auto 10px;" src="/assets/img/mail-outline.svg" />
      <h3 *ngIf="txtSuccess" class="mb-3 text-center">{{ "createAccount.modal.titleSuccess" | translate }}</h3>
      <p *ngIf="txtEmail" class="text-center">{{ "createAccount.modal.txtEmail" | translate }}</p>
      <p *ngIf="txtPassword" class="text-center">{{ "createAccount.modal.txtPassword" | translate }}</p>
      <p *ngIf="txtSuccess" class="text-center">{{ "createAccount.modal.txtSuccess" | translate }}</p>
      <p *ngIf="txtGenericErr" class="text-center">{{ "createAccount.modal.txtGenericErr" | translate }}</p>
    </div>
  </ng-container>
</app-custom-modal>
<app-loader  *ngIf="loader"></app-loader>
