import { Injectable } from '@angular/core';
import { Location } from "@angular/common";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  constructor(
    private route: Router,
    private location: Location
  ) {}

  /**
   * @param {string} route
   * @return {CallableFunction}
   */
  goTo(route:string) {
    this.route.navigate([`/${route}`]);
  }

  back(): void {
    this.location.back();
  }
}
