<div class="container" id="address-detail">
  <div class="row mb-5">
    <div class="col-md-6 text-md-start text-sm-center text-sm-left-c">
      <h1>
          <span class="border-bottom border-red border-3 pb-2 d-inline-block">
            {{ "address.viewAddressTxt" | translate }}
          </span>
      </h1>
    </div>
    <div class="col-md-6 text-md-end text-sm-start text-sm-right-c position-sm-absolute d-sm-block">
      <a (click)="cameBack()" class="btn-link">
        <i class="fa fa-arrow-left d-md-none text-red fz-25" aria-hidden="true"></i>
        <span class="d-md-block d-sm-none">
          < {{ "common.cameBack" | translate }}</span>
      </a>
    </div>
  </div>

  <form [formGroup]="addressDataForm">
    <div class="row mb-md-3">
      <div class="form-addressDataFormGroup col-md-6">
        <label for="firstname">
          <p class="par_2">{{ "commons.txtFirstname" | translate }}*</p>
        </label>
        <input class="form-control" id="firstname" type="text" formControlName="firstName" readonly="true"/>
        <!-- <app-error-form [fieldControl]="addressDataForm.controls['firstName']"
          codeError="errors.firstname"></app-error-form> -->
      </div>

      <div class="form-group col-md-6">
        <label for="lastname">
          <p class="par_2">{{ "commons.txtLastname" | translate }}*</p>
        </label>
        <input class="form-control" id="lastname" type="text" formControlName="lastName" readonly="true"/>
        <!-- <app-error-form [fieldControl]="addressDataForm.controls['lastName']"
          codeError="errors.lastname"></app-error-form> -->
      </div>
    </div>

    <div class="form-group mb-md-3">
      <label for="company">
        <p class="par_2">{{ "addressData.txtCompany" | translate }}</p>
      </label>
      <input class="form-control" id="company" type="company" formControlName="company" readonly="true"/>
    </div>

    <div class="row mb-md-3">
      <div class="form-group col-md-6">
        <label for="phone">
          <p class="par_2">{{ "addressData.txtPhone" | translate }}*</p>
        </label>
        <input class="form-control" id="phone" type="text" formControlName="phone" readonly="true"/>
        <!-- <app-error-form [fieldControl]="addressDataForm.controls['phone']" codeError="errors.phone"></app-error-form> -->
      </div>

      <div class="form-group col-md-6">
        <label for="fax">
          <p class="par_2">{{ "addressData.txtFax" | translate }}</p>
        </label>
        <input class="form-control" id="fax" type="text" formControlName="fax" readonly="true"/>
      </div>
    </div>

    <div class="row mb-md-3">
      <div class="form-group col-md-9">
        <label for="address">
          <p class="par_2">{{ "addressData.txtAddress" | translate }}*</p>
        </label>
        <input class="form-control" id="address" type="text" formControlName="address" readonly="true"/>
        <!-- <app-error-form [fieldControl]="addressDataForm.controls['address']"
          codeError="errors.address"></app-error-form> -->
      </div>

      <div class="form-group col-md-3">
        <label for="zip">
          <p class="par_2">{{ "addressData.txtZip" | translate }}</p>
        </label>
        <input class="form-control" id="zip" type="text" formControlName="zip" readonly="true"/>
      </div>
    </div>

    <div class="row mb-md-3">
      <div class="form-group col-md-6">
        <label for="city">
          <p class="par_2">{{ "addressData.txtCity" | translate }}*</p>
        </label>
        <input class="form-control" id="city" type="text" formControlName="city" readonly="true"/>
        <!-- <app-error-form [fieldControl]="addressDataForm.controls['city']" codeError="errors.city"></app-error-form> -->
      </div>

      <div class="form-group col-md-6">
        <label for="province">
          <p class="par_2">{{ "addressData.txtProvince" | translate }}*</p>
        </label>
        <input class="form-control" id="province" type="text" formControlName="province" readonly="true"/>
        <!-- <app-error-form [fieldControl]="addressDataForm.controls['province']"
          codeError="errors.province"></app-error-form> -->
      </div>
    </div>

    <div class="row mb-md-3 mb-sm-4">
      <div class="form-group col-md-6">
        <label for="fiscalCode">
          <p class="par_2">{{ "commons.txtFiscalCode" | translate }}*</p>
        </label>
        <input class="form-control" id="fiscalCode" type="text" formControlName="fiscalCode" readonly="true"/>
        <!-- <app-error-form [fieldControl]="addressDataForm.controls['fiscalCode']"
          codeError="errors.fiscalcode"></app-error-form> -->
      </div>

      <div class="form-group col-md-6">
        <label for="piva">
          <p class="par_2">{{ "commons.txtPiva" | translate }}</p>
        </label>
        <input class="form-control" id="piva" type="text" formControlName="piva" readonly="true"/>
      </div>
    </div>

    <!-- <div class="mb-md-4 mb-sm-4">
      <input mdbCheckbox type="checkbox" formControlName="defaultShipping" id="defaultShipping" />
      <label class="form-check-label" for="defaultShipping">
        <p class="par_2">{{ 'address.defaultBilling' | translate }}</p>
      </label>
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="addressDataForm.invalid" (click)="onSubmit()">
      {{ "buttons.save" | translate }}
    </button> -->
  </form>
</div>
