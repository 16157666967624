import { ApiService } from '../service/api.service';
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from '@angular/core';
import { GuestService } from '../service/guest.service';
import { first, take, tap } from 'rxjs';
import { GtmService } from '../service/gtm.service';

@Component({
  selector: 'app-guest-orders',
  templateUrl: './guest-orders.component.html',
  styleUrls: ['./guest-orders.component.scss'],
})
export class GuestOrdersComponent implements OnInit {

  showModal: boolean = false;
  constructor(
    private _guestService: GuestService,
    private route: ActivatedRoute,
    private router: Router,
    private _apiService: ApiService,
    private _gtmService: GtmService
  ) {}

  ngOnInit(): void {
    if (!this.route.snapshot.params['guid']) this.router.navigate(['/']);
    this._guestService.setGuid(this.route.snapshot.params['guid']);
    this._apiService.guestObs$.pipe(
      tap((response) => {
        if (response === 200) {
          this._gtmService.eventLoginGuid();
          this.router.navigate(['/orders']);
        } else {
          this.openPopup();
          setInterval(() => {
            this.router.navigate(['/'])
          }, 3000)
        }
      }),
      take(1)
    ).subscribe();
  }

  openPopup() {
    this.changeShowModal();
  }

  changeShowModal() {
    this.showModal = !this.showModal;
  }
  closeModal() {
    this.showModal = false;
  }
}
