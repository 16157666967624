<div class="footer-container">
  <div class="Footer-Content">
    <div class="Footer-Columns ContentWrapper">
      <div class="CmsBlock-Wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-3 col-sm-12 block-wrapper">
              <h6 class="block-title heading" *ngIf="!(_apiService.isLogged$ | async)">
                <a [href]="links.areaUtente">{{ "footer.areaUtente.title" | translate | uppercase}}</a>
              </h6>
              <div class="block-content" *ngIf="!(_apiService.isLogged$ | async)">
                <ul>
                  <li class="d-sm-block" style="margin-bottom: 1.5rem">
                    <span class="desc-content">
                      {{ "footer.areaUtente.desc" | translate }}
                    </span>
                  </li>
                  <li class="d-sm-block">
                    <a class="button2" [href]="links.areaUtente">
                      {{ "footer.areaUtente.btn" | translate | uppercase }}
                    </a>
                  </li>
                </ul>
              </div>
              <h6 class="block-title heading footer-social">
                {{ "footer.social.title" | translate | uppercase}}
              </h6>
              <div class="block-content social-icons">
                <ul class="">
                  <li>
                    <a [href]="links.social.facebook" target="_blank">
                      <i class="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a [href]="links.social.pinterest" target="_blank">
                      <i class="fa fa-pinterest-p"></i>
                    </a>
                  </li>
                  <li>
                    <a [href]="links.social.tiktok" target="_blank">
                      <img src="https://media.mondoconv.it/media/wysiwyg/icons8-tiktok-48_1_.png" alt="" loading="lazy"
                        class="Image-Image" style="width: 100%; height: 100%;">
                    </a>
                  </li>
                  <li>
                    <a [href]="links.social.youtube" target="_blank">
                      <i class="fa fa-youtube-play"></i>
                    </a>
                  </li>
                  <li>
                    <a [href]="links.social.instagram" target="_blank">
                      <i class="fa fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3 col-sm-12 block-wrapper">
              <h6 class="block-title heading">
                <a [href]="mondoConvUrl+links.servizioClienti.title">{{ "footer.servizioClienti.title" | translate |
                  uppercase }}</a>
              </h6>
              <div class="block-content">
                <ul>
                  <li>
                    <a [href]="mondoConvUrl+links.servizioClienti.cta1">{{
                      "footer.servizioClienti.cta1" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.servizioClienti.cta2">{{
                      "footer.servizioClienti.cta2" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.servizioClienti.cta3">{{
                      "footer.servizioClienti.cta3" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.servizioClienti.cta4">{{
                      "footer.servizioClienti.cta4" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.servizioClienti.cta5">{{
                      "footer.servizioClienti.cta5" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.servizioClienti.cta6">{{
                      "footer.servizioClienti.cta6" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.servizioClienti.cta7">{{
                      "footer.servizioClienti.cta7" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.servizioClienti.cta8">
                      {{ "footer.servizioClienti.cta8" | translate }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3 col-sm-12 block-wrapper">
              <h6 class="block-title heading">
                <a [href]="mondoConvUrl+links.acquistacomeVuoi.title">{{
                  "footer.acquistaComeVuoi.title" | translate | uppercase
                  }}</a>
              </h6>
              <div class="block-content">
                <ul>
                  <li>
                    <a [href]="mondoConvUrl+links.acquistacomeVuoi.cta1">{{
                      "footer.acquistaComeVuoi.cta1" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.acquistacomeVuoi.cta2">{{
                      "footer.acquistaComeVuoi.cta2" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.acquistacomeVuoi.cta3">{{
                      "footer.acquistaComeVuoi.cta3" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.acquistacomeVuoi.cta4">{{
                      "footer.acquistaComeVuoi.cta4" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.acquistacomeVuoi.cta5">{{
                      "footer.acquistaComeVuoi.cta5" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.acquistacomeVuoi.cta6">{{
                      "footer.acquistaComeVuoi.cta6" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.acquistacomeVuoi.cta7">{{
                      "footer.acquistaComeVuoi.cta7" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.acquistacomeVuoi.cta8">{{
                      "footer.acquistaComeVuoi.cta8" | translate
                      }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3 col-sm-12 block-wrapper">
              <h6 class="block-title heading">
                <a [href]="mondoConvGroupUrl">{{
                  "footer.azienda.title" | translate | uppercase
                  }}</a>
              </h6>
              <div class="block-content">
                <ul>
                  <li>
                    <a [href]="mondoConvGroupUrl+links.azienda.cta1">{{
                      "footer.azienda.cta1" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvGroupUrl+links.azienda.cta2">{{
                      "footer.azienda.cta2" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvGroupUrl+links.azienda.cta3">{{
                      "footer.azienda.cta3" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvGroupUrl+links.azienda.cta4">{{
                      "footer.azienda.cta4" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="blogMondoConvUrl">{{
                      "footer.azienda.cta5" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.azienda.cta6">{{
                      "footer.azienda.cta6" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.azienda.cta7" class="fw-bold">{{
                      "footer.azienda.cta7" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.azienda.cta8" class="fw-bold">{{
                      "footer.azienda.cta8" | translate
                      }}</a>
                  </li>
                  <li>
                    <a [href]="mondoConvUrl+links.azienda.cta9">{{
                      "footer.azienda.cta9" | translate
                      }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9 col-sm-12 block-wrapper">
              <h6
                class="block-title heading payment-title">
                <div class="borderTop1Desk"></div>
                <a [href]="mondoConvUrl+links.middleFooter.payment">
                  {{ "footer.middleFooter.payment.title" | translate | uppercase}}
                </a>
                <div class="borderTop2Desk"></div>
              </h6>
              <div class="row paymentBox paymentMobile" style="align-content: center; border-top: hidden;">
                <div class="col-md-2">
                  <a [href]="mondoConvUrl+links.middleFooter.payment">
                    <div class="image-payment">
                      <img
                        src="/assets/img/payment/visa.png"
                        alt="carte"
                        loading="lazy"
                        class="Image-Image">
                    </div>
                  </a>
                </div>
                <div class="col-md-2">
                  <a [href]="mondoConvUrl+links.middleFooter.payment">
                    <div class="image-payment">
                      <img
                        src="/assets/img/payment/findomestic.png"
                        alt="findomestic"
                        loading="lazy"
                        class="Image-Image">
                    </div>
                  </a>
                </div>
                <div class="col-md-2">
                  <a [href]="mondoConvUrl+links.middleFooter.payment">
                    <div class="image-payment">
                      <img
                        src="/assets/img/payment/bonifico-gen.png"
                        alt="bonifico"
                        loading="lazy"
                        class="Image-Image">
                    </div>
                  </a>
                </div>
                <div class="col-md-2">
                  <a [href]="mondoConvUrl+links.middleFooter.payment">
                    <div class="image-payment">
                      <img
                        src="/assets/img/payment/paypal.png"
                        alt="sim"
                        loading="paypal"
                        class="Image-Image">
                    </div>
                  </a>
                </div>
                <div class="col-md-2">
                  <a [href]="mondoConvUrl+links.middleFooter.payment">
                    <div class="image-payment">
                      <img
                        src="/assets/img/payment/cryptovalute.png"
                        alt="cryptovalute"
                        loading="lazy"
                        class="Image-Image">
                    </div>
                  </a>
                </div>
                <div class="col-md-2">
                  <a [href]="mondoConvUrl+links.middleFooter.payment">
                    <div class="image-payment">
                      <img
                        src="/assets/img/payment/consegna-pag.png"
                        alt="sim"
                        loading="lazy"
                        class="Image-Image">
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12 block-wrapper">
              <h6 class="block-title heading">
                <a [href]="mondoConvGroupUrl+links.middleFooter.lavoraConNoi">{{
                  "footer.middleFooter.lavoraConNoi.title" | translate | uppercase
                  }}</a>
              </h6>
              <div class="block-content">
                <ul>
                  <li class="d-sm-block" style="margin-bottom: 15px;">
                    <span class="desc-content">
                      {{ "footer.middleFooter.lavoraConNoi.des" | translate }}
                    </span>
                  </li>
                  <li class="d-sm-block">
                    <a class="button3" [href]="mondoConvGroupUrl+links.middleFooter.lavoraConNoi">
                      {{ "footer.middleFooter.lavoraConNoi.btn" | translate | uppercase }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section aria-label="" class="Footer-AltroContentWrapper">
    <div class="ContentWrapper Footer-AltroContent">
      <div>
        <p *ngIf="!isMobile">
          <a [href]="mondoConvUrl+links.subFooter.cta1">{{
            "footer.subFooter.cta1" | translate
            }}
          </a>
          &nbsp; &nbsp; - &nbsp; &nbsp;
          <a [href]="mondoConvUrl+links.subFooter.cta2">{{
            "footer.subFooter.cta2" | translate
            }}</a>
          &nbsp; &nbsp; - &nbsp; &nbsp;
          <a [href]="mondoConvUrl+links.subFooter.cta3">{{
            "footer.subFooter.cta3" | translate
            }}</a>
          &nbsp; &nbsp; - &nbsp; &nbsp;
          <a [href]="mondoConvUrl+links.subFooter.cta4">{{
            "footer.subFooter.cta4" | translate
            }}
          </a>
          &nbsp; &nbsp; - &nbsp; &nbsp;
          <a [href]="mondoConvUrl+links.subFooter.cta5">{{
            "footer.subFooter.cta5" | translate
            }}</a>
          &nbsp; &nbsp; - &nbsp; &nbsp;
          <a [href]="links.subFooter.cta6">{{
            "footer.subFooter.cta6" | translate
            }} </a>
        </p>
        <p *ngIf="isMobile" style="margin-top: 12px">
          <a [href]="mondoConvUrl+links.subFooter.cta1">{{
            "footer.subFooter.cta1" | translate
            }}
          </a>
          <br />
          &nbsp; - &nbsp;<br />
          <a [href]="mondoConvUrl+links.subFooter.cta2">{{
            "footer.subFooter.cta2" | translate
            }}</a>
          <br />
          &nbsp; - &nbsp;<br />
          <a [href]="mondoConvUrl+links.subFooter.cta3">{{
            "footer.subFooter.cta3" | translate
            }}</a>
          <br />
          &nbsp; - &nbsp;<br />
          <a [href]="mondoConvUrl+links.subFooter.cta4">{{
            "footer.subFooter.cta4" | translate
            }}
          </a>
          <br />
          &nbsp; - &nbsp;
          <br />
          <a [href]="mondoConvUrl+links.subFooter.cta5">{{
            "footer.subFooter.cta5" | translate
            }}</a>
          <br />
          &nbsp; - &nbsp;<br />
          <a [href]="links.subFooter.cta6">{{
            "footer.subFooter.cta6" | translate
            }}</a>
        </p>
      </div>
    </div>

  </section>
  <section aria-label="" class="Footer-AltroContentWrapper">
    <div class="ContentWrapper Footer-AltroContent Footer-Marketing">
      <p>
        {{ "footer.subFooter.marketing" | translate}}
      </p>
    </div>
  </section>

  <section aria-label="" class="Footer-CopyrightContentWrapper">
    <div class="ContentWrapper Footer-CopyrightContent">
      <div class="Footer-Logo">
        <img src="/assets/img/logo-negative.png" alt="" loading="lazy" class="Image" />
      </div>
      <span class="Footer-Copyright">
        ©{{ "footer.lastFooter.company" | translate }}
        <br />
        {{ "footer.lastFooter.REA" | translate }} | {{ "footer.lastFooter.capitaleSociale" | translate }} | {{
        "footer.lastFooter.vat" | translate | uppercase}} |
        <span role="button" tabindex="0" class="Footer-LegalNotes">{{ "footer.lastFooter.txt" | translate }}
        </span>
      </span>
    </div>
  </section>
  <div style="position: relative">
    <div role="button" tabindex="0" class="ScrollToTop" (click)="scrollToTop()" *ngIf="isVisible">
      <i class="fa fa-chevron-up"></i>
    </div>
  </div>
</div>
