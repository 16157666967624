import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceService } from '../service/device.service';

@Component({
  selector: 'app-service-modal',
  templateUrl: './service-modal.component.html',
  styleUrls: ['./service-modal.component.scss'],
})
export class ServiceModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter<any>();
  @Input() type!: string;
  @Input() title!:string;
  @Input() desc!:string;
  @Input() navettaRowOne:string|null = null;
  @Input() navettaRowTwo:string|null = null;
  @Input() navettaRowThree:string|null = null;
  @Input() navettaRowFour:string|null = null;

  isMobile: boolean = false;

  constructor(
    private _deviceService: DeviceService,
  )  {
    this.isMobile = this._deviceService._isMobile.getValue();
  }
  ngOnInit() {}

  close(event: any) {
    this.closeModal.emit(event);
  }
}
