import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { Voucher } from 'src/entities/voucher';

@Injectable({
  providedIn: 'root'
})
export class VouchersService {
  /** Observable */
  vouchersObs$: Observable<Voucher[]>;

  /** BehaviorSubject */
  setVouchers$: BehaviorSubject<Voucher[]> = new BehaviorSubject<Voucher[]>([]);
  vouchers$: Subject<Voucher[]> = new BehaviorSubject<Voucher[]>([]);
  vouchersCounter$: Subject<number> = new BehaviorSubject<number>(0);

  constructor(
  ) {
    this.vouchers$.asObservable();
    this.vouchersObs$ = this.setVouchers$.asObservable().pipe(
      tap((objVouchers: Voucher[]) => {
        if (objVouchers && objVouchers.length > 0){
          this.vouchers$.next(objVouchers);
          this.vouchersCounter$.next(objVouchers.length);
        }
      })
    );
  }
}
