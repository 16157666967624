import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-delivery',
  templateUrl: './order-delivery.component.html',
})

export class OrderDeliveryComponent implements OnInit {
  @Input() isMobile: boolean = false;
  @Input() isMsgRev: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
