import { Component, OnInit } from '@angular/core';
import { section } from 'src/entities/section';
import { sectionsStructure } from '../../config/menu';
import { DeviceService } from '../service/device.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss']
})

export class DashboardAdminComponent implements OnInit {

  elements: Array<section> = sectionsStructure;
  dashboard: Array<section> = [];
  isMobile: boolean = false;
  searchForm!: UntypedFormGroup;
  loader: boolean = false;

  constructor(
    private _deviceService: DeviceService,
    private _apiService: ApiService,
    private _fb: UntypedFormBuilder,
    private _route: Router
  ) {
    this.initForm();
    this.isMobile = this._deviceService._isMobile.getValue();
     this.searchForm.controls['email'].valueChanges
      .subscribe(x => {
        if (x && x.includes(' ')) {
          this.searchForm.controls['email'].setValue(x.trim())
        }
      })
  }

  ngOnInit(): void { }
  initForm() {
    this.searchForm = this._fb.group({
      email: ['', Validators.required],
    });
  }

  onSubmit() {
    if (this.searchForm.valid) {
      this.loader=true;
      this._apiService.getOrdini(this.searchForm.value.email.trim(), true).subscribe((response: number) => {
        this.loader=false;
        this._route.navigate(['/orders']);
      });
    }
  }

}


