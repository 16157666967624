import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class EncryptionService {
  private secretKey = 'Nt0ySuwBv7MZzMCgMDPx63BJj79Kzc74' // spsotare in env

  encrypt(pwd: string): string {
    const encrypted = CryptoJS.AES.encrypt(pwd, this.secretKey).toString();
    return encrypted;
  }

  encryptLinear(pwd: string): string {
    const editPwd = this.generateRandomString(16)+pwd+this.generateRandomString(12);
    const encodedWord = CryptoJS.enc.Utf8.parse(editPwd);
    return CryptoJS.enc.Base64.stringify(encodedWord);
  }

  decrypt(encrypted: string): string {
    const decrypted = CryptoJS.AES.decrypt(encrypted, this.secretKey);
    return CryptoJS.enc.Utf8.stringify(decrypted);
  }

  generateRandomString(maxLength: number): string {
    const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!£$%&/';
    let randomString = '';

    for (let i = 0; i < maxLength; i++) {
      randomString += possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));
    }

    return randomString;
  }
}
