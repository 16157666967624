import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { ApiService } from "../service/api.service";
import { CustomCookieService } from "../service/customCookie.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TokenService } from "../service/token.service";
import { catchError, map } from "rxjs/operators";
import { LoginService } from "../service/login.service";

@Injectable({
  providedIn: 'root'
})

export class LoginCookieGuard implements CanActivate {

  constructor (
    private _loginService: LoginService,
    private _customCookieService: CustomCookieService,
    private _tokenService: TokenService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const cookie = this._customCookieService.getCookie('auth_token');

    if (cookie) {
      return this._loginService.tokenLogin(cookie)
        .pipe(
          map(() => {
            this._tokenService.setToken(cookie);
            this._tokenService.setTypeToken('default');
            this.router.navigate(['/dashboard']);
            return false;
          }),
          catchError((err, caught) => {
            this._customCookieService.deleteCookie(['auth_token']);
             this.router.navigate(['/login']);
             return caught;
          })
        );
    }

    return true;
  }
}
